@charset "UTF-8";
html[data-theme=dark] body ::after,
html[data-theme=dark] body ::before {
  filter: brightness(0.8) contrast(1.2);
}
html[data-theme=dark] body blockquote:after,
html[data-theme=dark] body blockquote:before {
  filter: none;
}
html[data-theme=dark] body img,
html[data-theme=dark] body #single-intro,
html[data-theme=dark] body #currentTime,
html[data-theme=dark] body .actu .post-categories li a {
  filter: brightness(0.8) contrast(1.2);
}
html[data-theme=dark] body .overlay {
  opacity: 0.5;
}
html[data-theme=dark] body .home #virgule {
  opacity: 0.22;
}
html[data-theme=dark] body header .logo-darkmode {
  display: block;
}
html[data-theme=dark] body header video {
  display: none;
}
html[data-theme=dark] body .container-contact #menu-menu-principal .main-telephone svg #phone-alt-solid {
  fill: #000;
}
html[data-theme=dark] body .projet-colore {
  filter: brightness(0.8) contrast(1.2);
}
html[data-theme=dark] body .projets-colores #projets-deco-1:before,
html[data-theme=dark] body .projets-colores #projets-deco-2:after {
  background-image: url(../../assets/img/fleche-gauche-darkmode.svg);
}
html[data-theme=dark] body #contact-questions:before,
html[data-theme=dark] body #contact-candidature:before,
html[data-theme=dark] body #contact-projets:before {
  opacity: 0.1;
}
html[data-theme=dark] body.page-template-template-caroule .feedbackbox #frm_form_6_container #frm_field_46_container .frm-star-group .star-rating:before {
  content: "";
  background-image: url("../img/virgule-feedback-darkmode.svg");
  opacity: 0.4;
}
html[data-theme=dark] body.page-template-template-caroule .feedbackbox #frm_form_6_container #frm_field_46_container .frm-star-group .star-rating-on:before {
  content: "";
  background-image: url("../img/virgule-jaune-feedback-darkmode.svg");
  opacity: 0.9;
}
html[data-theme=dark] body.page-template-template-projets .projet {
  filter: brightness(0.8) contrast(1.2);
}
html[data-theme=dark] body.page-template #page-intro, html[data-theme=dark] body.page-template-default #page-intro {
  background-color: var(--grisf);
}
html[data-theme=dark] body.page-template #page-intro #c-blanc, html[data-theme=dark] body.page-template-default #page-intro #c-blanc {
  opacity: 0.1;
}
html[data-theme=dark] body.page-template #page-intro #vagues-jaune, html[data-theme=dark] body.page-template-default #page-intro #vagues-jaune {
  opacity: 0.8;
}
html[data-theme=dark] body.page-template #page-intro #page-intro-titre:after, html[data-theme=dark] body.page-template-default #page-intro #page-intro-titre:after {
  filter: brightness(0.8) contrast(1.2);
  content: "";
  background-image: url(../../assets/img/virgule-jaune.svg);
}
html[data-theme=dark] body.page-template #presentation-equipe, html[data-theme=dark] body.page-template-default #presentation-equipe {
  filter: brightness(0.8) contrast(1.2);
}
html[data-theme=dark] body.services-template-default.single-services #page-intro.bg-yellow, html[data-theme=dark] body.services-template-default.single-services #page-intro.bg-black {
  background-color: var(--grisf);
}
html[data-theme=dark] body.services-template-default.single-services #page-intro.bg-yellow #c-blanc, html[data-theme=dark] body.services-template-default.single-services #page-intro.bg-black #c-blanc {
  opacity: 0.1;
}
html[data-theme=dark] body.services-template-default.single-services #page-intro.bg-yellow #vagues-jaune, html[data-theme=dark] body.services-template-default.single-services #page-intro.bg-black #vagues-jaune {
  opacity: 0.8;
}
html[data-theme=dark] body.postid-518 section:nth-child(12) {
  background-color: #000 !important;
}

@media (prefers-color-scheme: dark) {
  body ::after,
  body ::before {
    filter: brightness(0.8) contrast(1.2);
  }
  body blockquote:after,
  body blockquote:before {
    filter: none;
  }
  body img,
  body #single-intro,
  body #currentTime,
  body .actu .post-categories li a {
    filter: brightness(0.8) contrast(1.2);
  }
  body .overlay {
    opacity: 0.5;
  }
  body .home #virgule {
    opacity: 0.22;
  }
  body header .logo-darkmode {
    display: block;
  }
  body header video {
    display: none;
  }
  body .container-contact #menu-menu-principal .main-telephone svg #phone-alt-solid {
    fill: #000;
  }
  body .projet-colore {
    filter: brightness(0.8) contrast(1.2);
  }
  body .projets-colores #projets-deco-1:before,
  body .projets-colores #projets-deco-2:after {
    background-image: url(../../assets/img/fleche-gauche-darkmode.svg);
  }
  body #contact-questions:before,
  body #contact-candidature:before,
  body #contact-projets:before {
    opacity: 0.1;
  }
  body.page-template-template-caroule .feedbackbox #frm_form_6_container #frm_field_46_container .frm-star-group .star-rating:before {
    content: "";
    background-image: url("../img/virgule-feedback-darkmode.svg");
    opacity: 0.4;
  }
  body.page-template-template-caroule .feedbackbox #frm_form_6_container #frm_field_46_container .frm-star-group .star-rating-on:before {
    content: "";
    background-image: url("../img/virgule-jaune-feedback-darkmode.svg");
    opacity: 0.9;
  }
  body.page-template-template-projets .projet {
    filter: brightness(0.8) contrast(1.2);
  }
  body.page-template #page-intro, body.page-template-default #page-intro {
    background-color: var(--grisf);
  }
  body.page-template #page-intro #c-blanc, body.page-template-default #page-intro #c-blanc {
    opacity: 0.1;
  }
  body.page-template #page-intro #vagues-jaune, body.page-template-default #page-intro #vagues-jaune {
    opacity: 0.8;
  }
  body.page-template #page-intro #page-intro-titre:after, body.page-template-default #page-intro #page-intro-titre:after {
    filter: brightness(0.8) contrast(1.2);
    content: url(../../assets/img/virgule-jaune.svg);
  }
  body.page-template #presentation-equipe, body.page-template-default #presentation-equipe {
    filter: brightness(0.8) contrast(1.2);
  }
  body.services-template-default.single-services #page-intro.bg-yellow, body.services-template-default.single-services #page-intro.bg-black {
    background-color: var(--grisf);
  }
  body.services-template-default.single-services #page-intro.bg-yellow #c-blanc, body.services-template-default.single-services #page-intro.bg-black #c-blanc {
    opacity: 0.1;
  }
  body.services-template-default.single-services #page-intro.bg-yellow #vagues-jaune, body.services-template-default.single-services #page-intro.bg-black #vagues-jaune {
    opacity: 0.8;
  }
  body.postid-518 section:nth-child(12) {
    background-color: #000 !important;
  }
}
@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/8de680/0000000000000000000132c1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/8de680/0000000000000000000132c1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/8de680/0000000000000000000132c1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}
@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/483ddc/0000000000000000000132c9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/483ddc/0000000000000000000132c9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/483ddc/0000000000000000000132c9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}
@font-face {
  font-family: "Gotham-Black";
  src: url("../fonts/gotham/black/gotham-black-webfont.eot");
  src: url("../fonts/gotham/black/gotham-black-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/black/gotham-black-webfont.woff") format("woff"), url("../fonts/gotham/black/gotham-black-webfont.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Bold";
  src: url("../fonts/gotham/bold/gotham-bold-webfont.eot");
  src: url("../fonts/gotham/bold/gotham-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/bold/gotham-bold-webfont.woff2") format("woff2"), url("../fonts/gotham/bold/gotham-bold-webfont.woff") format("woff"), url("../fonts/gotham/bold/gotham-bold-webfont.ttf") format("truetype"), url("../fonts/gotham/bold/gotham-bold-webfont.svg#gotham_boldregular") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Light";
  src: url("../fonts/gotham/light/gotham-light-webfont.eot");
  src: url("../fonts/gotham/light/gotham-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/light/gotham-light-webfont.woff2") format("woff2"), url("../fonts/gotham/light/gotham-light-webfont.woff") format("woff"), url("../fonts/gotham/light/gotham-light-webfont.ttf") format("truetype"), url("../fonts/gotham/light/gotham-light-webfont.svg#gotham_lightregular") format("svg");
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Light-Italic";
  src: url("../fonts/gotham/light/gotham-light-italic-webfont.eot");
  src: url("../fonts/gotham/light/gotham-light-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/light/gotham-light-italic-webfont.woff2") format("woff2"), url("../fonts/gotham/light/gotham-light-italic-webfont.woff") format("woff"), url("../fonts/gotham/light/gotham-light-italic-webfont.ttf") format("truetype"), url("../fonts/gotham/light/gotham-light-italic-webfont.svg#gotham_lightitalic") format("svg");
  font-display: swap;
}
:root {
  color-scheme: light dark;
  --jaunec: #ffee35;
  --jaunef: #ffdf05;
  --noir: #000000;
  --grisc: #f8f8f8;
  --grisf: #edecec;
  --grisfm: #989898;
  --blanc: #fff;
}

html[data-theme=light] {
  --jaunec: #ffee35;
  --jaunef: #ffdf05;
  --noir: #000000;
  --grisc: #f8f8f8;
  --grisf: #edecec;
  --blanc: #fff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --jaunec: #ffee35;
    --jaunef: #f8ea3e;
    --noir: #fff;
    --grisc: #1c1c1c;
    --grisf: #121212;
    --grisfm: #fff;
    --blanc: #000;
  }
  html[data-theme=light] {
    --jaunec: #ffee35;
    --jaunef: #ffdf05;
    --noir: #000000;
    --grisc: #f8f8f8;
    --grisf: #edecec;
    --grisfm: #989898;
    --blanc: #fff;
  }
}
html[data-theme=dark] {
  --jaunec: #ffee35;
  --jaunef: #f8ea3e;
  --noir: #fff;
  --grisc: #1c1c1c;
  --grisf: #121212;
  --grisfm: #fff;
  --blanc: #000;
}

html[data-theme=light] body ::after,
html[data-theme=light] body ::before {
  filter: none;
}
html[data-theme=light] body img,
html[data-theme=light] body #single-intro,
html[data-theme=light] body #currentTime,
html[data-theme=light] body .actu .post-categories li a {
  filter: none;
}
html[data-theme=light] body .overlay {
  opacity: 0.2;
}
html[data-theme=light] body .home #virgule {
  opacity: 0.33;
}
html[data-theme=light] body header .logo-darkmode {
  display: none;
}
html[data-theme=light] body header video {
  display: block;
}
html[data-theme=light] body .projet-colore {
  filter: none;
}
html[data-theme=light] body .projets-colores #projets-deco-1:before,
html[data-theme=light] body .projets-colores #projets-deco-2:after {
  background-image: url(../../assets/img/fleche-gauche.svg);
}
html[data-theme=light] body #contact-questions:before,
html[data-theme=light] body #contact-candidature:before,
html[data-theme=light] body #contact-projets:before {
  opacity: 1;
}
html[data-theme=light] body.page-template-template-caroule .feedbackbox #frm_form_6_container #frm_field_46_container .frm-star-group .star-rating:before {
  content: url("../img/virgule-feedback.svg");
  opacity: 1;
}
html[data-theme=light] body.page-template-template-caroule .feedbackbox #frm_form_6_container #frm_field_46_container .frm-star-group .star-rating-on:before {
  content: url("../img/virgule-jaune-feedback.svg");
  opacity: 1;
}
html[data-theme=light] body.page-template-template-projets .projet {
  filter: none;
}
html[data-theme=light] body.page-template-template-adn #page-intro, html[data-theme=light] body.page-template-template-candidature #page-intro, html[data-theme=light] body.page-template-template-caroule #page-intro, html[data-theme=light] body.page-template-template-candidature-graphiste #page-intro, html[data-theme=light] body.page-template-template-candidature-web #page-intro {
  background-color: #000;
}
html[data-theme=light] body.page-template-template-adn #page-intro #c-blanc, html[data-theme=light] body.page-template-template-candidature #page-intro #c-blanc, html[data-theme=light] body.page-template-template-caroule #page-intro #c-blanc, html[data-theme=light] body.page-template-template-candidature-graphiste #page-intro #c-blanc, html[data-theme=light] body.page-template-template-candidature-web #page-intro #c-blanc {
  opacity: 0.1 !important;
}
html[data-theme=light] body.page-template-template-adn #page-intro #vagues-jaune, html[data-theme=light] body.page-template-template-candidature #page-intro #vagues-jaune, html[data-theme=light] body.page-template-template-caroule #page-intro #vagues-jaune, html[data-theme=light] body.page-template-template-candidature-graphiste #page-intro #vagues-jaune, html[data-theme=light] body.page-template-template-candidature-web #page-intro #vagues-jaune {
  opacity: 1;
}
html[data-theme=light] body.page-template-template-metiers #page-intro {
  background-color: var(--jaunec);
}
html[data-theme=light] body.page-template-template-metiers #page-intro #c-blanc {
  opacity: 1;
}
html[data-theme=light] body.page-template-template-metiers #page-intro #vagues-jaune {
  opacity: 1;
}
html[data-theme=light] body.page-template-template-affiche #page-intro, html[data-theme=light] body.page-template-template-projets #page-intro, html[data-theme=light] body.post-type-archive-projets #page-intro {
  background-color: var(--grisc);
}
html[data-theme=light] body.page-template-template-affiche #page-intro #c-blanc, html[data-theme=light] body.page-template-template-projets #page-intro #c-blanc, html[data-theme=light] body.post-type-archive-projets #page-intro #c-blanc {
  opacity: 1;
}
html[data-theme=light] body.page-template-template-affiche #page-intro #vagues-jaune, html[data-theme=light] body.page-template-template-projets #page-intro #vagues-jaune, html[data-theme=light] body.post-type-archive-projets #page-intro #vagues-jaune {
  opacity: 1;
}
html[data-theme=light] body.services-template-default.single-services #page-intro.bg-black {
  background-color: var(--noir);
}
html[data-theme=light] body.services-template-default.single-services #page-intro.bg-black #c-blanc {
  opacity: 1;
}
html[data-theme=light] body.services-template-default.single-services #page-intro.bg-black #vagues-jaune {
  opacity: 1;
}
html[data-theme=light] body.services-template-default.single-services #page-intro.bg-yellow {
  background-color: var(--jaunec);
}
html[data-theme=light] body.services-template-default.single-services #page-intro.bg-yellow #c-blanc {
  opacity: 1;
}
html[data-theme=light] body.services-template-default.single-services #page-intro.bg-yellow #vagues-jaune {
  opacity: 1;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.wp-caption.aligncenter {
  margin: 0 auto;
}

.ux {
  max-width: 1440px;
  margin: 0 auto;
}

.bold {
  font-weight: bold;
  font-family: "Gotham-Bold";
}

html {
  overflow-x: hidden;
}

body {
  color: var(--noir);
  background-color: var(--grisc);
  min-height: 100vh;
  border-left: 60px solid var(--blanc);
  border-right: 60px solid var(--blanc);
  padding-bottom: 60px;
}
body.remove-scrolling {
  overflow: hidden;
}

img,
picture {
  max-width: 100%;
  height: auto;
}

svg path,
svg rect {
  fill: var(--noir);
}

.main-footer-expand {
  padding-bottom: 60px;
}

#progress {
  background-color: var(--jaunec);
  height: 3px;
  width: 0;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 99999 !important; /* Peut être important si vous avez un menu en position fixe */
  margin-left: 60px;
  max-width: calc(100% - 120px);
}

#home-hero-video {
  position: relative;
  width: calc(100vw - 120px);
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  margin-bottom: 120px;
}

.video-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
}

.overlay {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
}

.home .overlay {
  width: calc(100vw - 120px);
}

.section-row {
  padding-top: 150px;
  padding-bottom: 150px;
}
.section-row:nth-child(odd) h2,
.section-row:nth-child(odd) h3 {
  display: block;
  text-align: right;
}
.section-row h2 {
  margin-bottom: 0;
  position: relative;
}
.section-row h2:after {
  background-image: url(../../assets/img/virgule-jaune.svg);
  content: "";
  background-size: contain;
  width: 40px;
  height: 40px;
  display: block;
  position: absolute;
  right: -40px;
  bottom: -20px;
}
.section-row h3 {
  margin-bottom: 70px;
  position: relative;
  right: 60px;
  margin-top: 10px;
}

.vagues-4-jaune {
  width: 145px;
  height: auto;
  margin-bottom: 60px;
}

h1 {
  font-family: "Gotham-Black";
  font-size: 110px;
  line-height: 90px;
  letter-spacing: -2px;
}

h2 {
  font-family: "Gotham-Bold";
  font-size: 60px;
  line-height: 54px;
  display: inline-block;
  position: relative;
  letter-spacing: -2px;
}

h3 {
  font-family: "freight-big-pro", serif;
  font-size: 36px;
}

p,
ul li,
ol li {
  font-family: "Gotham-Light";
  font-size: 16px;
  line-height: 30px;
  color: var(--noir);
}

strong {
  font-family: "Gotham-Bold";
  font-weight: normal;
}

a {
  color: var(--noir);
  transition: color 0.5s ease;
}
a:hover {
  color: var(--jaunef);
  text-decoration: none;
  transition: color 0.5s ease;
}

blockquote {
  font-family: "Gotham-Light-Italic";
  font-size: 19px;
  color: var(--noir);
  padding: 30px 50px;
  margin: 45px auto;
  position: relative;
  border: 1px solid var(--jaunec);
}
blockquote:before {
  content: open-quote;
  color: var(--jaunec);
  background-color: var(--grisc);
  width: 60px;
  height: 60px;
  position: absolute;
  top: 15%;
  left: -30px;
  font-size: 100px;
  line-height: 1;
}
blockquote:after {
  content: close-quote;
  color: var(--jaunec);
  background-color: var(--grisc);
  width: 60px;
  height: 60px;
  position: absolute;
  top: 55%;
  right: -30px;
  font-size: 100px;
  line-height: 1;
}
blockquote p {
  margin-bottom: 0;
}

.btn-jaune a {
  border: 2px solid var(--jaunec);
  background-color: var(--jaunec);
  border-radius: 0;
  font-family: "Gotham-Bold";
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: all 0.35s linear;
  width: auto;
  max-width: 100%;
  border-radius: 0;
  padding: 20px;
  color: #000;
}
.btn-jaune a:after {
  content: "";
  background-size: contain;
  background-image: url("../img/fleche-scroll-blanc.svg");
  transform: rotate(-90deg);
  width: 18px;
  display: inline-block;
  margin-left: 20px;
}
.btn-jaune a:hover {
  background-color: #fff;
  transition: all 0.35s linear;
}
.btn-jaune a:hover:after {
  background-image: url("../img/fleche-scroll-jaune.svg");
}

span.note_lecteur {
  font-size: 13px !important;
  line-height: normal;
}

.mt30 {
  margin-top: 30px;
}

.mt60 {
  margin-top: 60px;
}

.mt90 {
  margin-top: 90px;
}

.mt120 {
  margin-top: 120px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb120 {
  margin-bottom: 120px;
}

.pt15 {
  padding-top: 15px;
}

.pt30 {
  padding-top: 30px;
}

.pt60 {
  padding-top: 60px;
}

.pt90 {
  padding-top: 90px;
}

.pt120 {
  padding-top: 120px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb90 {
  padding-bottom: 90px;
}

.pb120 {
  padding-bottom: 120px;
}

header {
  background-color: var(--blanc);
  height: 60px;
  line-height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9998;
}
header .logo-darkmode {
  display: none;
}
header.zindex {
  z-index: 99991;
}

#header-logo {
  display: inline-block;
  margin-left: 60px;
}
#header-logo img,
#header-logo picture {
  width: 100%;
  max-width: 160px;
}
#header-logo video {
  margin-top: 5px;
  width: 100%;
  max-width: 160px;
}

#header-hamburger {
  text-align: right;
  padding-right: 60px;
  position: absolute;
  z-index: 9999;
  right: 0;
  top: 0;
  display: flex;
}

.main-telephone {
  margin-right: 30px;
}
.main-telephone a {
  font-size: 18px;
}
.main-telephone a svg {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
}
.main-telephone a:hover svg {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.hamburger {
  margin-top: 17px;
  display: flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;
}
.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 30px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 4px;
  background-color: var(--noir);
  position: absolute;
  right: 0;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -8px;
  width: 24px;
}

.hamburger-inner::after {
  bottom: -8px;
  width: 20px;
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  width: 30px;
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  width: 30px;
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

#nav-main {
  background-color: var(--blanc);
  position: fixed;
  width: 84%;
  top: 60px;
  right: -100%;
  padding: 90px 90px;
  opacity: 0;
  z-index: 9998;
  transition: all 1s ease;
  height: calc(100vh - 120px);
  overflow-y: auto;
}
#nav-main .container-fluid {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
#nav-main #menu-menu-principal {
  padding: 0;
  margin: 0;
}
#nav-main #menu-menu-principal li {
  list-style: none;
  padding: 20px 0;
  width: 100%;
}
#nav-main #menu-menu-principal li:first-child {
  padding-top: 0;
}
#nav-main #menu-menu-principal li a {
  font-family: "Gotham-Bold";
  font-size: 50px;
  line-height: 52px;
  color: var(--noir);
  letter-spacing: -1px;
  background: transparent;
  transition: all 0.35s ease;
}
#nav-main #menu-menu-principal li a .main-nav-description {
  display: block;
  color: var(--grisfm);
  font-size: 30px;
  font-family: "freight-big-pro", serif;
  letter-spacing: 0;
  transition: all 0.35s linear;
}
#nav-main #menu-menu-principal li a:hover {
  background-image: url("../../assets/img/bg-nav.svg");
  background-repeat: repeat-x;
  background-position: center;
  letter-spacing: 8px;
  transition: all 0.5s ease;
}
#nav-main #menu-menu-principal li a:hover .main-nav-description {
  color: var(--noir);
  transition: all 0.5s linear;
}
#nav-main #nav-second {
  padding: 0;
  margin: 0;
}
#nav-main #nav-second li {
  list-style-type: none;
  display: inline-block;
  position: relative;
}
#nav-main #nav-second li:after {
  content: "|";
  color: var(--grisfm);
  margin-right: 15px;
}
#nav-main #nav-second li:last-child:after {
  content: none;
}
#nav-main #nav-second li a {
  font-family: "Gotham-Light";
  font-size: 15px;
  color: var(--grisfm);
  margin-right: 15px;
  transition: all 0.35s linear;
}
#nav-main #nav-second li a:hover {
  color: var(--noir);
  transition: all 0.35s linear;
}
#nav-main .container-equipe .content-image-equipe {
  display: flex;
  flex-direction: row;
}
#nav-main .container-equipe .content-image-equipe img,
#nav-main .container-equipe .content-image-equipe picture {
  flex-grow: 1;
  min-width: 0;
  object-fit: cover;
}
#nav-main .container-equipe #menu-menu-principal li {
  padding-bottom: 0;
}
#nav-main .container-equipe #menu-menu-principal li a {
  color: #000;
}
#nav-main .container-equipe #menu-menu-principal li a .main-nav-description {
  color: #000;
}
#nav-main .container-equipe #menu-menu-principal li a:hover {
  background-image: url("../../assets/img/bg-nav-blanc.svg");
}
#nav-main .container-equipe .content-nav {
  background-color: var(--jaunec);
  padding: 50px 45px;
}
#nav-main .container-equipe .content-nav #menu-menu-principal li {
  padding-bottom: 0;
}
#nav-main .container-recrutement {
  background-color: var(--grisc);
  padding: 50px 45px;
}
#nav-main .container-recrutement #menu-menu-principal li {
  padding-bottom: 0;
}
#nav-main .container-contact {
  background-color: var(--noir);
  padding: 50px 45px;
}
#nav-main .container-contact #menu-menu-principal li {
  padding-bottom: 0;
}
#nav-main .container-contact #menu-menu-principal li a {
  color: var(--blanc);
}
#nav-main .container-contact #menu-menu-principal li a .main-nav-description {
  color: var(--blanc);
}
#nav-main.is-active {
  opacity: 1;
  right: 0;
  transition: all 1s ease;
}

#nav-overlay {
  background-color: var(--jaunec);
  opacity: 0.85;
  z-index: 110;
  left: auto;
  right: 60px;
  display: none;
  position: fixed;
}

.page {
  padding-top: 60px;
}

#page-intro {
  height: calc(100vh - 120px);
  width: calc(100vw - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}
#page-intro #page-intro-titre {
  position: relative;
  z-index: 10;
  display: inline-block;
}
#page-intro #page-intro-titre:after {
  content: "";
  background-image: url(../../assets/img/virgule-jaune.svg);
  width: 40px;
  height: 40px;
  background-size: 40px;
  position: absolute;
  right: -50px;
  bottom: 10px;
}
#page-intro #page-intro-sous-titre {
  position: relative;
  font-family: "freight-big-pro", serif;
  z-index: 100;
  font-size: 55px;
  line-height: 1;
  margin-bottom: 0;
}
#page-intro #c-blanc {
  position: absolute;
  transform: rotate(180deg);
  -webkit-animation: rotation 30s linear infinite;
  z-index: 5;
}
#page-intro #c-noir-small,
#page-intro #c-jaune-small {
  position: absolute;
  transform: rotate(-90deg);
  z-index: 5;
  -webkit-animation: rotation 2s linear infinite;
}
#page-intro #vagues-jaune,
#page-intro #vagues-blanc,
#page-intro #vagues-noir,
#page-intro #virgule-gris {
  position: absolute;
}
#page-intro #vagues-noir {
  object-fit: contain;
}

.page-content {
  margin-bottom: 90px;
}

#scroll-icon {
  width: 30px;
  height: auto;
  position: absolute;
  bottom: 60px;
  left: calc(50% - 15px);
  animation-name: scrollIcon;
  animation-duration: 4s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

.page-cta {
  background-color: var(--grisf);
  font-family: "Gotham-Bold";
  font-size: 40px;
  color: var(--noir);
  text-align: center;
  display: block;
  padding-top: 90px;
  padding-bottom: 90px;
  width: 100%;
  margin: 0 0 80px;
  transition: all 0.5s ease;
  cursor: pointer;
}
.page-cta:hover {
  background-color: var(--jaunec);
  color: var(--noir);
  letter-spacing: 3px;
  transition: all 0.5s ease;
}

@keyframes scrollIcon {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.page-id-25 #page-intro #page-intro-titre:after,
.page-id-43 #page-intro #page-intro-titre:after,
.page-id-37 #page-intro #page-intro-titre:after {
  background-image: url(../../assets/img/virgule-blanc.svg);
}

.single {
  padding-top: 60px;
}
.single .single-single-container a {
  text-decoration: underline;
}
.single .single-single-container a:hover {
  text-decoration: underline;
}
.single #single-intro {
  height: 550px;
  background-size: cover;
  background-position: center;
}
.single #single-projets-intro {
  padding-left: 0;
  padding-right: 0;
  border-bottom: 2px solid var(--jaunec);
  height: auto;
}
.single #single-projets-intro img {
  object-fit: cover;
  width: 100%;
  max-height: 700px;
}
.single #single-projets-titre {
  margin-top: 80px;
  margin-bottom: 40px;
}
.single #single-projets-titre h1 {
  font-size: 46px;
  line-height: 48px;
  margin: 0;
}
.single #single-projets-titre #scroll-icon {
  position: relative;
  display: none;
  bottom: auto;
  margin-top: 30px;
}
.single #single-nav {
  background-color: var(--grisf);
  margin-bottom: 100px;
}
.single #single-nav ul {
  padding-left: 0;
  margin: 0 auto;
  text-align: center;
}
.single #single-nav ul li {
  display: inline-block;
  list-style-type: none;
}
.single #single-nav ul li a {
  text-transform: uppercase;
  font-family: "Gotham-Bold";
  font-size: 20px;
  color: var(--noir);
  padding: 25px 50px;
  display: block;
  transition: all 0.5s linear;
}
.single #single-nav ul li a#single-nav-prev img,
.single #single-nav ul li a#single-nav-prev picture,
.single #single-nav ul li a#single-nav-prev svg {
  height: 18px;
  transform: rotate(90deg);
  margin-right: 20px;
  opacity: 0;
  transition: all 0.5s linear 0.25s;
}
.single #single-nav ul li a#single-nav-next img,
.single #single-nav ul li a#single-nav-next picture,
.single #single-nav ul li a#single-nav-next svg {
  height: 18px;
  transform: rotate(-90deg);
  margin-left: 20px;
  opacity: 0;
}
.single #single-nav ul li:hover {
  background-color: var(--jaunec);
  transition: all 0.5s linear;
}
.single #single-nav ul li:hover img,
.single #single-nav ul li:hover picture,
.single #single-nav ul li:hover svg {
  opacity: 1 !important;
  transition: all 0.5s linear 0.25s;
}
.single .container-nav-back a {
  text-transform: uppercase;
  font-family: "Gotham-Bold";
  font-size: 20px;
  color: var(--noir);
  display: block;
  transition: all 0.5s linear;
}
.single .container-nav-back a#single-nav-back {
  height: 80px;
  padding: 25px !important;
  display: flex;
  align-items: center;
}
.single .container-nav-back a#single-nav-back .grid-back {
  height: 20px;
  display: flex;
}
.single .container-nav-back a#single-nav-back:hover {
  color: var(--jaunec);
  transition: all 0.5s linear;
}
.single .single-container {
  margin-top: 40px;
}
.single .single-container h1 {
  font-family: "Gotham-Bold";
  font-size: 60px;
  line-height: 62px;
  letter-spacing: normal;
  margin-top: 80px;
  margin-bottom: 80px;
}
.single .single-container p {
  margin-bottom: 30px;
}
.single .single-container p:last-child {
  margin-bottom: 0;
}
.single .single-container #single-metas {
  padding-left: 0;
}
.single .single-container #single-metas li {
  list-style-type: none;
  display: inline-block;
}
.single .single-container #single-metas #single-metas-date {
  font-family: "freight-big-pro", serif;
  font-size: 30px;
  color: var(--noir);
}
.single .single-container #single-metas #single-metas-category .post-categories {
  padding-left: 0;
  margin-left: 20px;
  margin-right: 0;
}
.single .single-container #single-metas #single-metas-category a {
  font-family: "Gotham-Bold";
  font-size: 20px;
  color: var(--jaunef);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.single .single-container #single-content a {
  font-family: "Gotham-Bold";
  text-decoration: underline;
}

.single-post {
  padding-bottom: 180px;
}
.single-post h1 {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: normal;
  margin-left: 12%;
  margin-right: 12%;
}
.single-post h2 {
  font-size: 25px;
  line-height: 35px;
  letter-spacing: normal;
  margin-top: 30px;
  margin-left: 12%;
  margin-right: 12%;
}
.single-post h3 {
  font-size: 20px;
  line-height: 30px;
  margin-top: 20px;
  margin-left: 12%;
  margin-right: 12%;
}
.single-post p,
.single-post blockquote,
.single-post ul,
.single-post ol,
.single-post iframe {
  margin-left: 12%;
  margin-right: 12%;
}
.single-post #single-content {
  margin-top: 60px;
  margin-bottom: 90px;
}
.single-post iframe {
  margin: 0;
}

#single-projets-title span {
  text-transform: uppercase;
  font-size: 30px;
  display: block;
}

.single-projets {
  padding-bottom: 120px;
}
.single-projets h2 {
  font-family: "Gotham-Bold";
  font-size: 28px;
  line-height: 32px;
  letter-spacing: normal;
  margin: 30px auto;
  display: block;
}
.single-projets h2:after {
  content: none;
}
.single-projets h3 {
  font-family: "Gotham-Bold";
  font-size: 24px;
}

.galerie-image {
  margin: 15px auto;
}

.projet-avis h2 {
  margin-top: 0;
}

.avis_item {
  background-color: var(--blanc);
  padding: 45px 60px 45px;
  border: 2px solid var(--jaunec);
  position: relative;
}
.avis_item a {
  background-color: var(--jaunec);
  font-family: "Gotham-Bold";
  padding: 4px 12px;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 2px;
  margin-top: 20px;
  display: block;
  position: absolute;
  bottom: 0px;
  right: 0;
  color: #000;
}
.avis_item a:hover {
  background-color: var(--noir);
  color: var(--jaunec);
}

.avis_texte {
  font-family: "freight-big-pro", serif;
  font-size: 26px;
  font-style: italic;
}

.avis_name {
  margin-bottom: 0;
}
.avis_name span {
  font-family: "Gotham-Bold";
}

footer {
  background-color: var(--blanc);
  min-height: 60px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  z-index: 999;
  /*  target the elemenent after the label*/
}
footer #footer-cta {
  font-family: "Gotham-Bold";
  font-size: 32px;
  line-height: 1;
  padding-left: 60px;
  margin: 40px auto;
  display: none;
  opacity: 0;
  position: relative;
}
footer #footer-cta span {
  font-family: "freight-big-pro", serif;
  font-size: 28px;
  color: var(--noir) !important;
  font-style: italic;
  display: block;
}
footer #footer-cta:hover {
  color: var(--jaunef);
}
footer #footer-social {
  text-align: right;
  position: absolute;
  right: 60px;
  bottom: 0;
}
footer #footer-social ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
footer #footer-social ul li {
  display: inline-block;
  list-style-type: none;
}
footer #footer-social ul li a,
footer #footer-social ul li a:active,
footer #footer-social ul li a:visited {
  width: 20px;
  height: 20px;
  color: var(--noir);
  margin: 0 6px;
  display: block;
  transform: rotate(0deg);
  transition: color 0.5s ease, transform 0.75s linear;
}
footer #footer-social ul li a:hover,
footer #footer-social ul li a:active:hover,
footer #footer-social ul li a:visited:hover {
  color: var(--jaunef);
  transform: rotate(360deg);
  transition: color 0.5s ease, transform 0.35s linear;
}
footer #footer-social ul li img {
  width: 20px;
}
footer.footer-expand {
  transition: all 0.35s ease;
}
footer.footer-expand #footer-cta {
  display: inline-block;
  opacity: 1;
  transition: all 0.35s ease;
}
footer.footer-expand #footer-cta:after {
  content: "";
  background-image: url(../../assets/img/fleche-scroll-jaune.svg);
  width: 20px;
  height: auto;
  display: block;
  position: absolute;
  right: -30px;
  bottom: -2px;
  transform: rotate(-90deg);
}
footer .message_darkmode {
  min-height: 0px;
  min-width: 0px;
  position: absolute;
  width: 285px;
  bottom: 40px;
  height: 50px;
  display: none;
  visibility: visible !important;
}
footer .message_darkmode .close-message {
  transform: translate(-50%, -50%);
  position: absolute;
  right: 0;
}
footer .message_darkmode .close-message .icon-close-message {
  cursor: pointer;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
footer .message_darkmode .close-message .icon-close-message svg {
  left: auto;
  right: 3px;
  width: 15px;
}
footer .message_darkmode .pop-message-darkmode {
  background-color: white;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  border-radius: 10px;
  text-align: center;
}
footer .message_darkmode .pop-message-darkmode p {
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
}
footer .message_darkmode .pop-message-darkmode:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 12%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: white;
  border-bottom: 0;
  margin-left: -10px;
  margin-bottom: -10px;
  filter: none;
}
footer .message_darkmode.close {
  display: none;
}
footer .message_darkmode.active {
  display: block;
}
footer .message_darkmode.active.close {
  display: none;
}
footer #theme_switch {
  position: relative;
  padding-left: 60px;
  margin-top: 15px;
  margin-bottom: 15px;
}
footer input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
footer label {
  width: 47px;
  height: 24px;
  background-color: #1c1c1c;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
}
footer .ball {
  width: 20px;
  height: 20px;
  background-color: #edecec;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}
footer .fa-moon,
footer .fa-sun {
  width: 15px;
  height: 15px;
}
footer .fa-moon path,
footer .fa-sun path {
  fill: var(--jaunef);
}

html[data-theme=dark] .ball {
  transform: translateX(22px);
}

html[data-theme=light] .ball {
  transform: translateX(0);
}

.home {
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home main {
  padding-bottom: 180px;
}
.home #virgule {
  height: 80%;
  width: auto;
  opacity: 0.33;
  position: absolute;
  top: 12%;
  right: 10%;
}
.home .overlay {
  width: calc(100vw - 60px);
  left: 0;
  z-index: -2;
}
.home h3 {
  margin-bottom: 30px;
}
.home .actu .wp-post-image {
  margin-bottom: 20px;
}
.home .actu .actu-titre {
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0;
}

.projets-colores {
  position: relative;
  margin-top: 90px;
  margin-bottom: 120px;
}
.projets-colores .row {
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.projets-colores #projets-deco-1 {
  position: absolute;
  right: 35%;
  top: -60px;
  font-family: "freight-big-pro", serif;
  font-style: italic;
  font-size: 22px;
}
.projets-colores #projets-deco-1:before {
  content: "";
  background-image: url("../../assets/img/fleche-gauche.svg");
  background-size: contain;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 18px;
  margin-right: 5px;
}
.projets-colores #projets-deco-2 {
  position: absolute;
  left: 35%;
  top: -60px;
  font-family: "freight-big-pro", serif;
  font-style: italic;
  font-size: 22px;
}
.projets-colores #projets-deco-2:after {
  content: "";
  background-image: url("../../assets/img/fleche-gauche.svg");
  background-size: contain;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 18px;
  margin-left: 5px;
  transform: rotate(-90deg);
}

.projet-colore a {
  display: flex;
  align-items: center;
  padding: 60px 30px;
  text-align: center;
  color: #fff;
  font-family: "Gotham-Bold";
  font-size: 28px;
  line-height: 26px;
  height: 100%;
}
.projet-colore a:hover {
  background-color: var(--jaunec) !important;
  color: #000;
}

.prestation-item {
  padding-left: 0;
  margin-bottom: 15px;
  font-size: 17px;
}
.prestation-item a:hover {
  transition: all 0.25s linear;
  color: #000;
}
.prestation-item span {
  font-family: "Gotham-Bold";
  background-color: var(--blanc);
  display: block;
  padding: 20px 30px;
  height: 100%;
}
.prestation-item span:hover {
  transition: all 0.25s linear;
  background-color: var(--jaunec);
  color: #000;
}

#home-clients {
  margin-bottom: 120px;
}
#home-clients ul {
  padding-left: 0;
  margin: 0;
}
#home-clients ul li {
  width: 33.33%;
  padding: 60px;
  list-style-type: none;
  float: left;
  border: 1px solid var(--grisf);
  text-align: center;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#home-clients ul li img,
#home-clients ul li picture,
#home-clients ul li svg {
  max-width: 170px;
  max-height: 50px;
}
#home-clients ul li .clients-svg {
  width: 170px;
  height: 50px;
  align-items: center;
  justify-content: center;
}

#currentTime {
  font-family: "freight-big-pro", serif;
  font-size: 30px;
  margin-bottom: 30px;
  margin-left: 8px;
  display: block;
  color: var(--jaunec);
}

#home-caption {
  padding: 0 5%;
  position: relative;
  z-index: 10;
  margin-top: -60px;
}
#home-caption p {
  font-family: "Gotham-Black";
  font-size: 90px;
  line-height: 90px;
  color: #fff;
}
#home-caption p:after {
  content: "";
  background-image: url(../../assets/img/virgule-jaune.svg);
  width: 40px;
  height: 40px;
  background-size: 40px;
  position: absolute;
  right: 70px;
  bottom: 58px;
}
#home-caption h1 {
  font-family: "freight-big-pro", serif;
  font-size: 38px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 0;
  letter-spacing: 0;
}

.page-template-template-metiers #page-intro {
  background-color: var(--jaunec);
}
.page-template-template-metiers #page-intro #page-intro-titre {
  left: 9%;
}
.page-template-template-metiers #page-intro #page-intro-sous-titre {
  left: -5%;
}
.page-template-template-metiers #page-intro #c-blanc {
  width: 40%;
  height: auto;
  top: 12%;
  left: 8%;
}
.page-template-template-metiers #page-intro #c-noir-small {
  width: 44px;
  height: auto;
  top: 12%;
  right: 10%;
}
.page-template-template-metiers #page-intro #vagues-jaune {
  width: 40%;
  top: 29%;
  left: 15%;
}
.page-template-template-metiers #page-intro #vagues-noir {
  bottom: 15%;
  right: 10%;
  width: 10%;
}

#coeurdemetier-conseil {
  position: relative;
}
#coeurdemetier-conseil:before {
  content: url("../img/virgule-blanc.svg");
  width: 50%;
  height: auto;
  display: block;
  position: absolute;
  top: 10%;
  left: 25%;
}

#coeurdemetier-conception {
  position: relative;
}
#coeurdemetier-conception:before {
  content: "";
  background-size: contain;
  background-image: url("../img/virgule-blanc.svg");
  width: 50%;
  height: auto;
  position: absolute;
  top: 10%;
  right: 25%;
}

#coeurdemetier-creation {
  position: relative;
}
#coeurdemetier-creation:before {
  content: "";
  background-size: contain;
  background-image: url("../img/virgule-blanc.svg");
  width: 50%;
  height: auto;
  position: absolute;
  top: 10%;
  left: 25%;
}

.page-template-template-adn #page-intro,
.page-template-template-candidature #page-intro,
.page-template-template-candidature-graphiste #page-intro,
.page-template-template-candidature-web #page-intro,
.page-template-template-caroule #page-intro {
  background-color: var(--noir);
}
.page-template-template-adn #page-intro #page-intro-titre,
.page-template-template-candidature #page-intro #page-intro-titre,
.page-template-template-candidature-graphiste #page-intro #page-intro-titre,
.page-template-template-candidature-web #page-intro #page-intro-titre,
.page-template-template-caroule #page-intro #page-intro-titre {
  color: #fff;
  right: -7%;
}
.page-template-template-adn #page-intro #page-intro-sous-titre,
.page-template-template-candidature #page-intro #page-intro-sous-titre,
.page-template-template-candidature-graphiste #page-intro #page-intro-sous-titre,
.page-template-template-candidature-web #page-intro #page-intro-sous-titre,
.page-template-template-caroule #page-intro #page-intro-sous-titre {
  color: #fff;
  left: -15%;
}
.page-template-template-adn #page-intro #c-blanc,
.page-template-template-candidature #page-intro #c-blanc,
.page-template-template-candidature-graphiste #page-intro #c-blanc,
.page-template-template-candidature-web #page-intro #c-blanc,
.page-template-template-caroule #page-intro #c-blanc {
  width: 40%;
  height: auto;
  left: 60%;
  opacity: 0.07;
}
.page-template-template-adn #page-intro #c-jaune-small,
.page-template-template-candidature #page-intro #c-jaune-small,
.page-template-template-candidature-graphiste #page-intro #c-jaune-small,
.page-template-template-candidature-web #page-intro #c-jaune-small,
.page-template-template-caroule #page-intro #c-jaune-small {
  width: 44px;
  height: auto;
  top: 12%;
  left: 10%;
}
.page-template-template-adn #page-intro #vagues-blanc,
.page-template-template-candidature #page-intro #vagues-blanc,
.page-template-template-candidature-graphiste #page-intro #vagues-blanc,
.page-template-template-candidature-web #page-intro #vagues-blanc,
.page-template-template-caroule #page-intro #vagues-blanc {
  width: 40%;
  top: 42%;
  left: 20%;
  opacity: 0.2;
}
.page-template-template-adn #page-intro #vagues-jaune,
.page-template-template-candidature #page-intro #vagues-jaune,
.page-template-template-candidature-graphiste #page-intro #vagues-jaune,
.page-template-template-candidature-web #page-intro #vagues-jaune,
.page-template-template-caroule #page-intro #vagues-jaune {
  width: 40%;
  bottom: -180px;
  left: 0;
  transform: rotate(90deg);
}
.page-template-template-adn #page-intro #double-vagues-blanc,
.page-template-template-candidature #page-intro #double-vagues-blanc,
.page-template-template-candidature-graphiste #page-intro #double-vagues-blanc,
.page-template-template-candidature-web #page-intro #double-vagues-blanc,
.page-template-template-caroule #page-intro #double-vagues-blanc {
  width: 2%;
  height: auto;
  position: absolute;
  bottom: 10%;
  right: 15%;
  display: inline-block;
  z-index: 6;
  opacity: 1;
}

#adn-heritage {
  position: relative;
}
#adn-heritage:before {
  content: "";
  background-size: contain;
  background-image: url("../img/vagues-2-blanc.svg");
  width: 250%;
  height: auto;
  position: absolute;
  top: 20%;
  left: -130%;
}

.page-template-template-affiche #page-intro,
.page-template-template-projets #page-intro,
.post-type-archive-projets #page-intro {
  background-color: var(--grisc);
}
.page-template-template-affiche #page-intro #page-intro-titre,
.page-template-template-projets #page-intro #page-intro-titre,
.post-type-archive-projets #page-intro #page-intro-titre {
  color: var(--noir);
  left: 120px;
}
.page-template-template-affiche #page-intro #page-intro-sous-titre,
.page-template-template-projets #page-intro #page-intro-sous-titre,
.post-type-archive-projets #page-intro #page-intro-sous-titre {
  position: relative;
  color: var(--noir);
  right: 90px;
}
.page-template-template-affiche #page-intro #c-blanc,
.page-template-template-projets #page-intro #c-blanc,
.post-type-archive-projets #page-intro #c-blanc {
  width: 35%;
  height: auto;
  top: 0%;
  left: 0%;
  transform: rotate(0) !important;
  display: inline-block;
  opacity: 1;
}
.page-template-template-affiche #page-intro #vagues-jaune,
.page-template-template-projets #page-intro #vagues-jaune,
.post-type-archive-projets #page-intro #vagues-jaune {
  width: 50%;
  top: 35%;
  left: 32%;
  opacity: 1;
}
.page-template-template-affiche #page-intro #vagues-noir,
.page-template-template-projets #page-intro #vagues-noir,
.post-type-archive-projets #page-intro #vagues-noir {
  bottom: 15%;
  right: 10%;
  width: 10%;
}
.page-template-template-affiche #page-intro #c-noir-small,
.page-template-template-projets #page-intro #c-noir-small,
.post-type-archive-projets #page-intro #c-noir-small {
  width: 60px;
  height: auto;
  top: 18%;
  left: 20%;
}
.page-template-template-affiche #page-intro #virgule-gris,
.page-template-template-projets #page-intro #virgule-gris,
.post-type-archive-projets #page-intro #virgule-gris {
  top: 15%;
  right: 10%;
  width: 110px;
}

.projet {
  transition: all 0.5s linear;
}
.projet .projet-infos {
  padding: 150px 90px 150px;
  text-align: center;
}
.projet .projet-infos .projet-date {
  font-family: "freight-big-pro", serif;
  font-size: 30px;
  color: #fff;
  position: relative;
  z-index: 2;
}
.projet .projet-infos .projet-titre {
  font-family: "Gotham-Bold";
  font-size: 38px;
  line-height: 38px;
  color: #fff;
  letter-spacing: 1px;
  z-index: 2;
}
.projet .projet-infos .projet-desc {
  margin-top: 100px;
}
.projet .projet-infos .projet-desc p {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}
.projet .projet-infos .projet-competences {
  position: relative;
  padding: 0;
  z-index: 2;
  margin-top: 15px;
}
.projet .projet-infos .projet-competences li {
  font-family: "Gotham-Light";
  font-size: 16px;
  list-style-type: none;
  display: inline-block;
  color: #fff;
}
.projet .projet-infos .projet-competences li:after {
  content: "|";
  margin: 0 10px;
}
.projet .projet-infos .projet-competences li:last-child:after {
  content: none;
}
.projet:last-child {
  margin-bottom: 80px;
}
.projet .projet-image {
  background-size: cover;
  background-position: center;
  transition: all 0.5s linear;
}
.projet:hover {
  transition: all 0.5s linear;
}
.projet:hover .projet-image {
  position: relative;
  filter: blur(1px);
}
.projet:hover .projet-image:before {
  content: "";
  background-size: contain;
  background-image: url(../../assets/img/fleche-scroll-blanc.svg);
  width: 160px;
  height: auto;
  display: block;
  transform: rotate(-90deg);
  position: absolute;
  top: calc(50% - 80px);
  right: calc(50% - 50px);
}

.page-template-template-projets .main-footer-expand {
  padding-bottom: 0;
}

.wp-caption {
  max-width: 100%;
}

.wp-caption-text {
  font-size: 12px;
  padding: 5px 15px;
  text-align: center;
}
.wp-caption-text a {
  font-family: Gotham Regular;
}

.page-template-template-actualites #page-intro {
  background-color: var(--jaunec);
}
.page-template-template-actualites #page-intro #page-intro-titre {
  color: var(--noir);
  left: 60px;
}
.page-template-template-actualites #page-intro #page-intro-sous-titre {
  position: relative;
  color: var(--noir);
  right: 90px;
  width: 80%;
  text-align: right;
}
.page-template-template-actualites #page-intro #c-blanc {
  width: 40%;
  height: auto;
  top: -30%;
  right: 5%;
  display: inline-block;
}
.page-template-template-actualites #page-intro #vagues-jaune {
  width: 54%;
  top: 46%;
  left: 5%;
}
.page-template-template-actualites #page-intro #c-noir-small {
  width: 40px;
  height: auto;
  top: 10%;
  right: 10%;
}
.page-template-template-actualites #page-intro #double-vagues-blanc {
  width: 2%;
  height: auto;
  position: absolute;
  top: 10%;
  left: 30%;
  transform: rotate(90deg);
  display: inline-block;
  z-index: 6;
  opacity: 0.5;
}
.page-template-template-actualites .page-cta {
  margin: 0 0 20px;
}
.page-template-template-actualites .actu-multiple:nth-child(1) {
  display: none;
}

.archive {
  padding-top: 60px;
}
.archive #page-intro {
  background-color: var(--noir);
}
.archive #page-intro #page-intro-titre {
  color: white;
  left: 60px;
}
.archive #page-intro #page-intro-sous-titre {
  position: relative;
  color: white;
  right: 90px;
  width: 100%;
  text-align: right;
}
.archive #page-intro #c-blanc {
  width: 40%;
  height: auto;
  top: -30%;
  right: 5%;
  opacity: 0.07;
  display: inline-block;
}
.archive #page-intro #vagues-jaune {
  width: 45%;
  bottom: 16%;
  left: 5%;
}
.archive #page-intro #c-noir-small {
  width: 40px;
  height: auto;
  top: 10%;
  right: 10%;
}
.archive #page-intro #double-vagues-blanc {
  width: 2%;
  height: auto;
  position: absolute;
  top: 10%;
  left: 30%;
  transform: rotate(90deg);
  display: inline-block;
  z-index: 6;
  opacity: 0.5;
}
.archive .actu-image {
  height: 380px;
  max-height: 100%;
  object-fit: cover;
}

.actu {
  margin-bottom: 60px;
}
.actu .actu-titre {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -2px;
  margin-bottom: 15px;
  color: var(--noir);
}
.actu .actu-titre:hover {
  color: var(--noir);
}
.actu .actu-image {
  margin-bottom: 30px;
}
.actu .actu-extrait p {
  margin-bottom: 0;
}
.actu .actu-citation {
  background-color: var(--jaunec);
  padding: 30px;
  font-family: "freight-big-pro", serif;
  font-size: 40px;
  line-height: 40px;
  font-weight: 900;
  color: #fff;
  margin-bottom: 30px;
  position: relative;
  height: 480px;
}
.actu .actu-citation:after {
  position: absolute;
  bottom: 30px;
  right: 30px;
  content: "”";
  font-size: 60px;
  line-height: 0;
  color: var(--noir);
}
.actu .actu-icone {
  background-color: var(--noir);
  margin-bottom: 30px;
  height: 480px;
}
.actu .post-categories {
  padding-left: 10px;
  display: inline-block;
}
.actu .post-categories li {
  list-style-type: none;
}
.actu .post-categories li a {
  font-family: "Gotham-Bold";
  text-transform: uppercase;
  color: var(--jaunef);
}
.actu .actu-date {
  margin-top: 10px;
  font-family: "freight-big-pro", serif;
  font-size: 20px;
  display: inline-block;
}

#actu-principale {
  margin-top: 60px;
}
#actu-principale #actu-principale-texte {
  align-self: center;
  flex-direction: column;
}
#actu-principale .actu-titre {
  font-size: 50px;
  line-height: 53px;
  display: block;
}
#actu-principale img {
  width: 100%;
  height: 100%;
  max-height: 540px;
  object-fit: cover;
}

#actualites .container:hover .actu {
  opacity: 0.5;
  transition: all 1s ease;
}
#actualites .container .actu:hover {
  opacity: 1;
  transition: all 1s ease;
}

#actualites-filtres {
  background-color: var(--grisf);
}
#actualites-filtres ul {
  padding-left: 0;
  margin: 0 auto;
  text-align: center;
}
#actualites-filtres ul li {
  display: inline-block;
  list-style-type: none;
  background-color: var(--grisf);
  padding: 12px 12px;
  text-transform: uppercase;
  font-family: "Gotham-Bold";
  font-size: 16px;
}
#actualites-filtres ul li:hover {
  background-color: var(--jaunec);
}
#actualites-filtres .searchandfilter ul li.sf-field-category {
  padding: 0;
}
#actualites-filtres .searchandfilter ul li.sf-field-category:hover {
  background-color: transparent;
}
#actualites-filtres .searchandfilter ul li.sf-field-category .sf-input-checkbox,
#actualites-filtres .searchandfilter ul li.sf-field-category .sf-input-radio {
  display: none;
}
#actualites-filtres .searchandfilter ul li.sf-field-category ul li.sf-level-0:nth-child(1) {
  display: none;
}
#actualites-filtres .searchandfilter li label {
  cursor: pointer;
}

.page-template-template-equipe #page-intro {
  display: block;
  background-color: #fff000;
  width: calc(100vw - 120px);
  height: auto;
  position: relative;
}
.page-template-template-equipe #page-intro #page-intro-titre {
  position: absolute;
  top: 10%;
  right: 30%;
}
.page-template-template-equipe #page-intro #page-intro-sous-titre {
  position: absolute;
  top: 20%;
  left: 35%;
  text-align: left;
  width: 80%;
  font-size: 40px;
}
.page-template-template-equipe #page-intro #c-blanc {
  width: 40%;
  height: auto;
  top: 12%;
  left: 30%;
  display: none;
}
.page-template-template-equipe #page-intro #vagues-jaune {
  width: 40%;
  top: 6%;
  left: 5%;
  z-index: 2;
}
.page-template-template-equipe #page-intro #vagues-noir {
  top: 25%;
  left: 10%;
  width: 10%;
  transform: rotate(90deg);
}
.page-template-template-equipe #page-intro .pin {
  position: absolute;
  cursor: pointer;
}
.page-template-template-equipe #page-intro #david {
  bottom: 40%;
  left: 11%;
  animation-name: rotation;
  animation-duration: 12s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
}
.page-template-template-equipe #page-intro #maxime {
  bottom: 40%;
  left: 50%;
  animation-name: rotation;
  animation-duration: 10s;
  animation-delay: -4s;
  animation-iteration-count: infinite;
}
.page-template-template-equipe #page-intro #claire {
  top: 40%;
  left: 57%;
  animation-name: rotation;
  animation-duration: 11s;
  animation-delay: -1s;
  animation-iteration-count: infinite;
}
.page-template-template-equipe #page-intro #helene {
  top: 47%;
  right: 24%;
  animation-name: rotation;
  animation-duration: 12s;
  animation-delay: -2s;
  animation-iteration-count: infinite;
}
.page-template-template-equipe #page-intro #aly {
  bottom: 20%;
  right: 34%;
  animation-name: rotation;
  animation-duration: 14s;
  animation-delay: -4s;
  animation-iteration-count: infinite;
}
.page-template-template-equipe #page-intro #marion {
  bottom: 25%;
  left: 37%;
  animation-name: rotation;
  animation-duration: 14s;
  animation-delay: -4s;
  animation-iteration-count: infinite;
}
.page-template-template-equipe #page-intro #melissa {
  top: 40%;
  left: 25%;
  animation-name: rotation;
  animation-duration: 14s;
  animation-delay: -4s;
  animation-iteration-count: infinite;
}
.page-template-template-equipe #page-intro #caroline {
  bottom: 44%;
  right: 10%;
  animation-name: rotation;
  animation-duration: 14s;
  animation-delay: -4s;
  animation-iteration-count: infinite;
}
.page-template-template-equipe #photo-equipe {
  position: relative;
  display: block;
  width: calc(100vw - 120px);
  height: auto;
}
.page-template-template-equipe #presentation-equipe {
  width: calc(100vw - 120px);
}
.page-template-template-equipe #presentation-equipe .photo-salarie,
.page-template-template-equipe #presentation-equipe .infos-salarie {
  width: 50%;
  height: 650px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
}
.page-template-template-equipe #presentation-equipe .infos-salarie {
  background-color: black;
  padding: 100px;
  float: left;
}
.page-template-template-equipe #presentation-equipe h3 {
  color: var(--jaunef);
  font-family: "Gotham-Black";
  font-size: 70px;
  margin-bottom: 0px;
}
.page-template-template-equipe #presentation-equipe .fonction-salarie {
  font-family: "freight-big-pro", serif;
  color: #fff;
  font-size: 25px;
}
.page-template-template-equipe #presentation-equipe p {
  color: #fff;
}
.page-template-template-equipe #presentation-equipe .petit-plus {
  color: var(--jaunec);
  font-family: "Gotham-Black";
  font-size: 30px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.page-template-template-equipe #presentation-equipe .vagues-4-jaune {
  margin: 20px 0px;
}
.page-template-template-equipe #presentation-equipe .vagues-4-blanc {
  width: 230px;
  transform: rotate(90deg);
  position: absolute;
  right: -40px;
  top: 150px;
}
.page-template-template-equipe #presentation-equipe .carousel-control-prev,
.page-template-template-equipe #presentation-equipe .carousel-control-next {
  width: 6%;
}
.page-template-template-equipe #presentation-equipe .carousel-control-next-icon {
  background-image: url("../img/fleche-scroll-jaune.svg");
  transform: rotate(-90deg);
}
.page-template-template-equipe #presentation-equipe .carousel-control-prev-icon {
  background-image: url("../img/fleche-scroll-jaune.svg");
  transform: rotate(90deg);
}
.page-template-template-equipe #presentation-equipe .david {
  background-image: url("../img/equipe/portrait-david.png");
}
.page-template-template-equipe #presentation-equipe .claire {
  background-image: url("../img/equipe/portrait-claire.png");
}
.page-template-template-equipe #presentation-equipe .maxime {
  background-image: url("../img/equipe/portrait-maxime.png");
}
.page-template-template-equipe #presentation-equipe .helene {
  background-image: url("../img/equipe/portrait-helene.png");
}
.page-template-template-equipe #presentation-equipe .romain {
  background-image: url("../img/equipe/portrait-romain.png");
}
.page-template-template-equipe .glide__arrow {
  display: block;
  width: 30px;
  height: auto;
  border: 0;
  text-shadow: none;
  box-shadow: none;
  padding: 3px;
}
.page-template-template-equipe .glide__arrow--right img,
.page-template-template-equipe .glide__arrow--right picture {
  transform: rotate(-90deg);
}
.page-template-template-equipe .glide__arrow--left img,
.page-template-template-equipe .glide__arrow--left picture {
  transform: rotate(90deg);
}

#construction {
  position: absolute;
  width: 60%;
  top: 16%;
  left: 3%;
  z-index: 1;
}

.membre {
  padding-top: 50px;
  padding-bottom: 50px;
}
.membre:first-child {
  margin-top: 50px;
}
.membre:last-child {
  margin-bottom: 100px;
}
.membre .vagues-4-jaune {
  margin-top: 10px;
  margin-bottom: 30px;
}

.membre-texte {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.membre-prenom {
  color: var(--jaunec);
}

.membre-metiers {
  font-family: "freight-big-pro", serif;
  font-size: 45px;
  line-height: 1;
  color: var(--noir);
  margin-bottom: 30px;
}

.page-template-template-contact #page-intro {
  background-color: var(--noir);
}
.page-template-template-contact #page-intro #page-intro-titre {
  color: #fff;
  left: 120px;
}
.page-template-template-contact #page-intro #page-intro-sous-titre {
  position: relative;
  color: #fff;
  right: 90px;
}
.page-template-template-contact #page-intro #c-blanc {
  width: 40%;
  height: auto;
  bottom: -2%;
  left: 2%;
  transform: rotate(-90deg) !important;
  display: inline-block;
  opacity: 0.07;
}
.page-template-template-contact #page-intro #vagues-blanc {
  width: 54%;
  top: 40%;
  left: 42%;
  opacity: 0.07;
}
.page-template-template-contact #page-intro #c-jaune-small {
  width: 60px;
  height: auto;
  top: 10%;
  right: 10%;
}
.page-template-template-contact #page-intro #double-vagues-jaune {
  width: 2%;
  height: auto;
  position: absolute;
  top: 16%;
  left: 12%;
  z-index: 6;
}
.page-template-template-contact #form_formulairedecontact input[type=text],
.page-template-template-contact #form_formulairedecontact input[type=tel],
.page-template-template-contact #form_formulairedecontact input[type=email] {
  background-color: var(--grisf);
  height: 55px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
  font-family: "Gotham-Light";
}
.page-template-template-contact #form_formulairedecontact textarea {
  background-color: var(--grisf);
  min-height: 285px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.page-template-template-contact #form_formulairedecontact #frm_field_12_container {
  margin-bottom: 0;
}
.page-template-template-contact #form_formulairedecontact .frm_primary_label {
  font-family: "Gotham-Bold";
  font-size: 16px;
  color: var(--noir);
}
.page-template-template-contact #form_formulairedecontact .frm_checkbox {
  float: left;
  width: 20%;
  text-align: center;
  color: var(--noir);
  border: 1px solid var(--grisc);
}
.page-template-template-contact #form_formulairedecontact .frm_checkbox label {
  padding: 20px 10px;
  text-transform: uppercase;
  font-family: "Gotham-Light";
  font-size: 14px;
  background-color: var(--grisf);
  margin-bottom: 0;
  cursor: pointer;
  transition: all 0.35s linear;
}
.page-template-template-contact #form_formulairedecontact .frm_checkbox input {
  opacity: 0;
}
.page-template-template-contact #form_formulairedecontact .checked {
  background-color: var(--jaunec) !important;
  transition: all 0.35s linear;
}
.page-template-template-contact #form_formulairedecontact .frm_submit {
  text-align: right;
}
.page-template-template-contact #form_formulairedecontact .frm_submit button {
  border: 2px solid var(--jaunec);
  background-color: var(--jaunec);
  border-radius: 0;
  font-family: "Gotham-Bold";
  font-size: 15px;
  text-transform: uppercase;
  color: #000;
  padding: 20px;
  letter-spacing: 1.5px;
  transition: all 0.35s linear;
  position: relative;
  width: 100%;
}
.page-template-template-contact #form_formulairedecontact .frm_submit button:after {
  content: "";
  background-size: contain;
  background-image: url("../img/fleche-scroll-blanc.svg");
  transform: rotate(-90deg);
  width: 18px;
  display: inline-block;
  margin-left: 40px;
}
.page-template-template-contact #form_formulairedecontact .frm_submit button:hover {
  background-color: #fff;
  transition: all 0.35s linear;
}
.page-template-template-contact #form_formulairedecontact .frm_submit button:hover:after {
  content: "";
  background-size: contain;
  background-image: url("../img/fleche-scroll-jaune.svg");
}

#map {
  height: 100%;
  min-height: 500px;
}

#contact-postuler {
  border: 2px solid var(--jaunec);
  border-radius: 0;
  font-family: "Gotham-Bold";
  font-size: 15px;
  text-transform: uppercase;
  color: var(--noir);
  padding: 20px;
  letter-spacing: 1.5px;
  margin-top: 20px;
  display: inline-block;
  transition: all 0.35s linear;
  position: relative;
}
#contact-postuler:after {
  background-image: url("../img/fleche-scroll-jaune.svg");
  content: "";
  background-size: contain;
  transform: rotate(-90deg);
  width: 18px;
  display: inline-block;
  margin-left: 40px;
}
#contact-postuler:hover {
  background-color: #fff;
  transition: all 0.35s linear;
}

#contact-questions {
  position: relative;
}
#contact-questions:before {
  content: "";
  background-size: contain;
  background-image: url("../img/fleche-scroll-blanc.svg");
  width: 70%;
  height: auto;
  display: block;
  position: absolute;
  top: 20%;
  left: 10%;
  transform: rotate(-90deg);
}
#contact-questions h2 {
  right: 12%;
}
#contact-questions ul {
  padding-left: 0;
  margin-bottom: 0;
}
#contact-questions ul li {
  display: inline-block;
  list-style-type: none;
}
#contact-questions ul li a,
#contact-questions ul li a:active,
#contact-questions ul li a:visited {
  color: var(--noir);
  margin: 0 6px;
  display: block;
  transform: rotate(0deg);
  transition: color 0.5s ease, transform 0.75s linear;
}
#contact-questions ul li a:hover,
#contact-questions ul li a:active:hover,
#contact-questions ul li a:visited:hover {
  color: var(--jaunef);
  transform: rotate(360deg);
  transition: color 0.5s ease, transform 0.35s linear;
}

#contact-projets {
  position: relative;
}
#contact-projets:before {
  content: "";
  background-size: contain;
  background-image: url("../img/fleche-scroll-blanc.svg");
  background-position-y: 20%;
  background-position-x: 0%;
  width: 70%;
  height: auto;
  display: block;
  position: absolute;
}

#contact-candidature {
  position: relative;
  margin-bottom: 100px;
}
#contact-candidature:before {
  content: "";
  background-size: contain;
  background-image: url("../img/fleche-scroll-blanc.svg");
  width: 70%;
  height: auto;
  position: absolute;
  top: 20%;
  left: 0;
  transform: rotate(-180deg);
}
#contact-candidature h2 {
  right: 12%;
}

.page-id-399 #page-intro {
  background-color: var(--jaunec);
}
.page-id-399 #page-intro #page-intro-titre {
  right: 0;
  text-align: right;
}
.page-id-399 #page-intro #page-intro-sous-titre {
  left: 30%;
  text-align: left;
  width: 80%;
}
.page-id-399 #page-intro #c-blanc {
  width: 40%;
  height: auto;
  top: 4%;
  left: 35%;
}
.page-id-399 #page-intro #c-noir-small {
  width: 44px;
  height: auto;
  bottom: 12%;
  right: 20%;
}
.page-id-399 #page-intro #vagues-jaune {
  width: 40%;
  top: 50%;
  left: 25%;
  height: 150px;
}
.page-id-399 #page-intro #vagues-noir {
  top: 25%;
  left: 10%;
  width: 10%;
  transform: rotate(90deg);
}
.page-id-399 .page-content {
  padding-top: 120px;
  padding-bottom: 90px;
}
.page-id-399 .page-content h2 {
  font-size: 70px;
  margin-top: 40px;
  margin-bottom: 30px;
}
.page-id-399 .page-content h3 {
  font-size: 38px;
}
.page-id-399 .page-content ul li {
  font-family: "Gotham-Light";
}

#nav-fin-projets .wp-post-image,
#nav-fin-projets .nav-fin-projet {
  display: block;
}
#nav-fin-projets .wp-post-image .nav-fin-projet-titre,
#nav-fin-projets .nav-fin-projet .nav-fin-projet-titre {
  font-size: 20px;
  letter-spacing: 1px;
  transition: all 0.35s linear;
  margin-left: 0;
  margin-top: 30px;
  font-family: "Gotham-Bold";
}

#nav-fin-projets:hover .nav-fin-projet {
  opacity: 0.5;
  transition: all 0.5s ease;
}
#nav-fin-projets .nav-fin-projet:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

#retour-projets {
  display: block;
  font-family: "Gotham-Light";
  padding: 15px 25px;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  margin: 45px auto 0;
  transition: all 0.35s linear;
  position: relative;
  letter-spacing: 2px;
  transition: all 0.35s linear;
  color: var(--noir);
}
#retour-projets:after {
  background-image: url("../../assets/img/virgule-jaune.svg");
  content: "";
  background-size: contain;
  width: 40px;
  height: 40px;
  position: absolute;
  right: calc(50% - 20px);
  bottom: calc(50% - 20px);
  z-index: -3;
  transform: rotate(0);
  transition: all 0.35s linear;
}
#retour-projets:hover {
  color: var(--noir);
  letter-spacing: 6px;
  transition: all 0.35s linear;
}
#retour-projets:hover:after {
  transform: rotate(45deg);
  transition: all 0.35s linear;
}

#lire-plus-titre {
  display: inline-block;
  font-family: "freight-big-pro", serif;
  font-size: 42px;
  margin-bottom: 50px;
  padding: 5px 10px;
  position: relative;
  letter-spacing: inherit;
  color: var(--noir);
}
#lire-plus-titre:after {
  content: "";
  background-size: contain;
  background-color: url("../../assets/img/bg-brush-jaune.svg");
  position: absolute;
  top: 3px;
  left: -10px;
  width: calc(100% + 20px);
  z-index: -2;
  padding: 5px;
  transform: rotate(-2deg);
}

.btn-brush {
  position: relative;
  font-family: "freight-big-pro", serif;
  font-size: 22px;
  color: #000;
  margin-top: 30px;
  transition: all 0.35s linear;
}
.btn-brush:after {
  content: "";
  background-size: contain;
  background-image: url("../../assets/img/bg-brush-jaune.svg");
  position: absolute;
  top: 0;
  left: -10px;
  width: calc(100% + 20px);
  z-index: -2;
}
.btn-brush:hover {
  letter-spacing: 2px;
  color: #000;
  transition: all 0.35s linear;
}

#joinup-widget {
  margin-left: auto !important;
  margin-right: auto !important;
  bottom: 40px !important;
}

.legende {
  font-family: "Gotham-Light";
  font-style: italic;
  font-size: 15px;
  color: #777;
}

.error404 #section404 {
  background-color: #000;
  height: 100vh;
  width: calc(100vw - 120px);
  display: flex;
  align-items: center;
  text-align: center;
}
.error404 #virgule404 {
  width: 100px;
  transform: rotateZ(225deg);
}
.error404 h1 {
  color: var(--jaunec);
  margin-top: 60px;
}
.error404 p {
  color: #fff;
  font-family: "freight-big-pro", serif;
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 60px;
}
.error404 p:after {
  content: none;
}

.page-template-template-services #page-intro {
  background-color: var(--jaunec);
}
.page-template-template-services #page-intro #page-intro-titre {
  left: 9%;
}
.page-template-template-services #page-intro #page-intro-titre:after {
  background-image: url(../../assets/img/virgule-blanc.svg);
}
.page-template-template-services #page-intro #page-intro-sous-titre {
  left: -5%;
}
.page-template-template-services #page-intro #c-blanc {
  width: 40%;
  height: auto;
  top: 12%;
  left: 8%;
}
.page-template-template-services #page-intro #c-noir-small {
  width: 44px;
  height: auto;
  top: 12%;
  right: 10%;
}
.page-template-template-services #page-intro #vagues-jaune {
  width: 40%;
  top: 29%;
  left: 15%;
  height: 150px;
}
.page-template-template-services #page-intro #vagues-noir {
  bottom: 15%;
  right: 10%;
  width: 10%;
  height: 150px;
}
.page-template-template-services .hover-thumbnail {
  height: 66px;
  display: none;
  position: relative;
}
.page-template-template-services .hover-thumbnail img,
.page-template-template-services .hover-thumbnail picture {
  height: inherit;
  object-fit: cover;
  max-width: 180px;
}
.page-template-template-services .hover-template-services:hover {
  display: flex;
}
.page-template-template-services .hover-template-services:hover .hover-thumbnail {
  display: block;
}
.page-template-template-services .hover-template-services:hover span {
  width: 100%;
  border-left: 0;
}

#newsletter {
  padding-bottom: 60px;
}

#newsletter-cta {
  background-color: #f2f2f2;
  padding-top: 60px;
  padding-bottom: 140px;
}
#newsletter-cta iframe {
  background-color: transparent !important;
}
#newsletter-cta iframe form.mj-form {
  background-color: red !important;
}
#newsletter-cta form.mj-form .mj-embed-shadow .mj-embedded-content {
  background-color: transparent !important;
  box-shadow: none !important;
}

.snow-canvas {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 999999;
}

.services-template-default.single-services #page-intro #c-blanc {
  width: 40%;
  height: auto;
  top: -30%;
  right: 5%;
  display: inline-block;
}
.services-template-default.single-services #page-intro #c-noir-small {
  width: 40px;
  height: auto;
  top: 10%;
  right: 10%;
}
.services-template-default.single-services #page-intro #vagues-jaune {
  width: 54%;
  top: 46%;
  left: 5%;
  height: 150px;
}
.services-template-default.single-services #page-intro #double-vagues-blanc {
  width: 2%;
  height: auto;
  position: absolute;
  top: 10%;
  left: 30%;
  transform: rotate(90deg);
  display: inline-block;
  z-index: 6;
  opacity: 0.5;
}
.services-template-default.single-services #page-intro.bg-yellow {
  background-color: var(--jaunec);
}
.services-template-default.single-services #page-intro.bg-yellow #page-intro-titre::after {
  content: url(../../assets/img/virgule-blanc.svg);
}
.services-template-default.single-services #page-intro.bg-yellow #c-blanc .cls-1 {
  fill: white;
}
.services-template-default.single-services #page-intro.bg-yellow #c-noir-small .cls-1 {
  fill: var(--noir);
}
.services-template-default.single-services #page-intro.bg-yellow #vagues-jaune .st0 {
  fill: var(--jaunef);
}
.services-template-default.single-services #page-intro.bg-yellow #double-vagues-blanc .st0 {
  fill: var(--noir);
}
.services-template-default.single-services #page-intro.bg-yellow #scroll-icon .st0 {
  fill: white;
}
.services-template-default.single-services #page-intro.bg-black {
  background-color: var(--noir);
}
.services-template-default.single-services #page-intro.bg-black #page-intro-titre,
.services-template-default.single-services #page-intro.bg-black p {
  color: white;
}
.services-template-default.single-services #page-intro.bg-black #c-noir-small .cls-1 {
  fill: white;
}
.services-template-default.single-services #page-intro.bg-black #vagues-jaune .st0 {
  fill: var(--jaunec);
}
.services-template-default.single-services #page-intro.bg-black #scroll-icon .st0 {
  fill: var(--jaunec);
}
.services-template-default.single-services .virgule-bg {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.services-template-default.single-services .single-container h2 {
  display: flex;
  flex-direction: column;
}
.services-template-default.single-services .single-container h2::after {
  content: "";
  background-image: url(../../assets/img/vagues-4-jaune.svg);
  background-size: contain;
  width: 175px;
  height: 30px;
  margin-top: 30px;
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  margin-top: 30px;
}
.services-template-default.single-services .single-container h3 {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.services-template-default.single-services .section-lien-article .legende-lien-article {
  position: absolute;
  right: 5%;
  bottom: 30px;
  font-family: "freight-big-pro", serif;
  font-style: italic;
  font-size: 22px;
}
.services-template-default.single-services .section-lien-article .legende-lien-article::before {
  content: "";
  background-image: url("../../assets/img/fleche-gauche.svg");
  background-size: contain;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 18px;
  margin-right: 5px;
}
.services-template-default.single-services .section-lien-article img,
.services-template-default.single-services .section-lien-article picture {
  max-height: 500px;
  width: 100%;
  object-fit: cover;
}
.services-template-default.single-services .section-lien-article .plus-deux-colonnes img,
.services-template-default.single-services .section-lien-article .plus-deux-colonnes picture {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
}
.services-template-default.single-services.postid-906 #page-intro #vagues-jaune {
  top: 65%;
}
.services-template-default.single-services.postid-906 #page-intro #page-intro-titre {
  white-space: nowrap;
}
.services-template-default.single-services.postid-906 #page-intro #page-intro-sous-titre {
  text-align: right;
}
.services-template-default.single-services.postid-996 #page-intro #vagues-jaune {
  top: 65%;
}
.services-template-default.single-services.postid-908 #page-intro #c-blanc, .services-template-default.single-services.postid-917 #page-intro #c-blanc {
  left: -10%;
  top: 10%;
}
.services-template-default.single-services.postid-908 #page-intro #vagues-jaune, .services-template-default.single-services.postid-917 #page-intro #vagues-jaune {
  display: none;
}
.services-template-default.single-services.postid-908 #page-intro #page-intro-titre, .services-template-default.single-services.postid-917 #page-intro #page-intro-titre {
  text-align: right;
}
.services-template-default.single-services.postid-908 #page-intro #double-vagues-blanc, .services-template-default.single-services.postid-917 #page-intro #double-vagues-blanc {
  opacity: 1;
  transform: rotate(0);
  top: auto;
  bottom: -15%;
}
.services-template-default.single-services.postid-893 #page-intro #vagues-jaune, .services-template-default.single-services.postid-920 #page-intro #vagues-jaune {
  top: 65%;
  width: 40%;
  height: 150px;
}
.services-template-default.single-services.postid-893 #page-intro #double-vagues-blanc, .services-template-default.single-services.postid-920 #page-intro #double-vagues-blanc {
  top: -5%;
  left: 5%;
}
.services-template-default.single-services.postid-893 #page-intro #c-blanc, .services-template-default.single-services.postid-920 #page-intro #c-blanc {
  top: 5%;
}
.services-template-default.single-services.postid-893 #page-intro #page-intro-titre, .services-template-default.single-services.postid-920 #page-intro #page-intro-titre {
  text-align: left;
}
.services-template-default.single-services.postid-893 #page-intro #page-intro-titre::after, .services-template-default.single-services.postid-920 #page-intro #page-intro-titre::after {
  display: none;
}
.services-template-default.single-services.postid-893 #page-intro #page-intro-sous-titre, .services-template-default.single-services.postid-920 #page-intro #page-intro-sous-titre {
  text-align: right;
}
.services-template-default.single-services.postid-900 #page-intro #vagues-jaune {
  top: 65%;
  width: 40%;
  height: 150px;
}
.services-template-default.single-services.postid-900 #page-intro #double-vagues-blanc {
  left: auto;
  right: 30%;
}
.services-template-default.single-services.postid-900 #page-intro #c-blanc {
  right: auto;
  left: 5%;
}
.services-template-default.single-services.postid-900 #page-intro #page-intro-sous-titre {
  text-align: right;
}
.services-template-default.single-services.postid-900 #page-intro #page-intro-titre {
  left: 0%;
  text-align: left;
}
.services-template-default.single-services.postid-919 #page-intro #vagues-jaune {
  top: 65%;
  width: 40%;
  height: 150px;
}
.services-template-default.single-services.postid-919 #page-intro #double-vagues-blanc {
  transform: rotate(0deg);
  left: 15%;
  top: -5%;
}
.services-template-default.single-services.postid-919 #page-intro #page-intro-sous-titre {
  text-align: right;
}
.services-template-default.single-services.postid-919 #page-intro #page-intro-titre {
  left: 0%;
  text-align: left;
}
.services-template-default.single-services.postid-913 #page-intro #vagues-jaune {
  top: 65%;
  left: 5%;
  height: 150px;
}
.services-template-default.single-services.postid-913 #page-intro #double-vagues-blanc {
  left: auto;
  right: 30%;
  top: 0%;
}
.services-template-default.single-services.postid-913 #page-intro #c-blanc {
  right: auto;
  left: 2%;
}
.services-template-default.single-services.postid-913 #page-intro #page-intro-sous-titre {
  text-align: left;
}
.services-template-default.single-services.postid-913 #page-intro #page-intro-titre {
  left: 0%;
  text-align: right;
}

.page-template-template-avis #page-caroule-mag {
  border-bottom: 2px solid var(--jaunec);
}
.page-template-template-avis #page-caroule-mag img {
  width: 100%;
  object-fit: cover;
}
.page-template-template-avis #caroule-mag-titre {
  margin-top: 80px;
}
.page-template-template-avis #caroule-mag-titre h1 {
  font-size: 46px;
  line-height: 48px;
  margin: 0;
}
.page-template-template-avis #caroule-mag-titre h1 span {
  position: relative;
}
.page-template-template-avis #caroule-mag-titre h1 span:after {
  background-image: url(../../assets/img/virgule-jaune.svg);
  width: 40px;
  height: 40px;
  content: "";
  background-size: contain;
  position: absolute;
  right: -35px;
  bottom: -35px;
}
.page-template-template-avis #caroule-mag-titre p {
  margin-top: 50px;
}
.page-template-template-avis .section-row {
  padding-top: 60px;
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_form_field .frm-star-group .star-rating {
  width: 56px;
  height: 36px;
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_form_field .frm-star-group .star-rating::before {
  content: url("../img/virgule-feedback.svg");
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_form_field .frm-star-group input + label {
  width: 56px;
  height: 36px;
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_form_field .frm-star-group input + label::before {
  content: url("../img/virgule-feedback.svg");
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_form_field .frm-star-group .star-rating-on::before {
  content: url("../img/virgule-jaune-feedback.svg");
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable input[type=text],
.page-template-template-avis .feedbackbox .frm_style_style-formidable input[type=tel],
.page-template-template-avis .feedbackbox .frm_style_style-formidable input[type=email] {
  background-color: var(--grisf);
  height: 55px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
  font-family: "Gotham-Light";
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable textarea {
  background-color: var(--grisf);
  min-height: 285px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_primary_label {
  font-family: "Gotham-Bold";
  font-size: 16px;
  color: var(--noir);
  margin-bottom: 12px;
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_submit {
  text-align: right;
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_submit button {
  border: 2px solid var(--jaunec);
  background-color: var(--jaunec);
  border-radius: 0;
  font-family: "Gotham-Bold";
  font-size: 15px;
  text-transform: uppercase;
  color: #000;
  padding: 20px;
  letter-spacing: 1.5px;
  transition: all 0.35s linear;
  position: relative;
  width: 100%;
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_submit button:after {
  content: "";
  background-size: contain;
  background-image: url("../img/fleche-scroll-blanc.svg");
  transform: rotate(-90deg);
  width: 18px;
  display: inline-block;
  margin-left: 40px;
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_submit button:hover {
  background-color: #fff;
  transition: all 0.35s linear;
}
.page-template-template-avis .feedbackbox .frm_style_style-formidable .frm_submit button:hover:after {
  background-image: url("../img/fleche-scroll-jaune.svg");
}

#newsletter .container-newsletter {
  padding: 30px 30px 10px;
  background-color: var(--jaunec);
}
#newsletter .container-newsletter p {
  color: black;
  font-style: italic;
  font-size: 18px;
  font-family: "freight-big-pro", serif;
  margin-bottom: 0;
}
#newsletter .container-newsletter h4 {
  color: black;
  font-family: "Gotham-Bold";
  font-size: 22px;
}
#newsletter .container-newsletter .frm_style_style-formidable {
  margin-top: 15px;
}
#newsletter .container-newsletter .frm_style_style-formidable .frm_primary_label {
  display: none;
}
#newsletter .container-newsletter .frm_style_style-formidable input[type=email] {
  background-color: #fff;
  height: 61px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
  font-family: "Gotham-Light";
}
#newsletter .container-newsletter .frm_style_style-formidable .frm_submit {
  text-align: right;
}
#newsletter .container-newsletter .frm_style_style-formidable .frm_submit button {
  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 0;
  font-family: "Gotham-Bold";
  font-size: 15px;
  text-transform: uppercase;
  color: #000;
  padding: 20px;
  letter-spacing: 1.5px;
  transition: all 0.35s linear;
  position: relative;
  width: auto;
}
#newsletter .container-newsletter .frm_style_style-formidable .frm_submit button:after {
  content: "";
  background-size: contain;
  background-image: url("../img/fleche-scroll-jaune.svg");
  transform: rotate(-90deg);
  width: 18px;
  display: inline-block;
  margin-left: 20px;
}
#newsletter .container-newsletter .frm_style_style-formidable .frm_submit button:hover {
  border: 2px solid black;
  background-color: black;
  color: #fff;
  transition: all 0.35s linear;
}
#newsletter .container-newsletter .frm_style_style-formidable .frm_submit button:hover:after {
  background-image: url("../img/fleche-scroll-jaune.svg");
}
#newsletter .container-newsletter .frm_style_style-formidable .frm_form_field {
  width: 80%;
  margin-bottom: 0;
}
#newsletter .container-newsletter .frm_style_style-formidable .frm_fields_container {
  align-items: center;
  display: inline-flex;
  width: 100%;
}

@media only screen and (max-width: 1920px) {
  #nav-main #menu-menu-principal li a {
    font-size: 40px;
    line-height: 42px;
  }
  #nav-main #menu-menu-principal li a .main-nav-description {
    font-size: 26px;
  }
}
@media only screen and (max-width: 1441px) {
  .page-template-template-equipe #page-intro #page-intro-titre {
    top: 7%;
    right: 25%;
  }
  .projet .projet-infos {
    padding: 110px 80px 110px;
  }
  .projet .projet-infos .projet-date {
    font-size: 28px;
  }
  .projet .projet-infos .projet-titre {
    font-size: 34px;
    line-height: 34px;
  }
  .projet .projet-infos .projet-competences li {
    font-size: 14px;
  }
  .projet .projet-infos .projet-desc {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 1366px) {
  #page-intro {
    width: 100%;
  }
  #page-intro #page-intro-sous-titre {
    font-size: 45px;
    line-height: 38px;
  }
  .page-template-template-adn #page-intro #page-intro-sous-titre {
    left: 0;
  }
  .projet .projet-infos {
    padding: 100px 60px 100px;
  }
  .projet .projet-infos .projet-date {
    font-size: 26px;
  }
  .projet .projet-infos .projet-titre {
    font-size: 30px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 1200px) {
  #nav-main {
    width: 90%;
    padding: 90px 70px;
  }
  #nav-main #nav-second {
    position: relative;
    bottom: 0;
    padding-top: 30px;
    padding-bottom: 60px;
  }
  #home-caption p {
    font-size: 60px;
    line-height: 62px;
  }
  #home-caption h1 {
    font-size: 35px;
  }
  #page-intro #page-intro-sous-titre {
    left: -5%;
  }
  .page-template-template-candidature #page-intro #page-intro-sous-titre {
    left: -5%;
  }
  .page-template-template-metiers #page-intro #page-intro-titre {
    left: 4%;
  }
  .page-template-template-contact #form_formulairedecontact .frm_checkbox label {
    padding: 10px 0;
    font-size: 12px;
  }
  .page-template-template-equipe #page-intro #page-intro-titre {
    font-size: 80px;
    right: 22%;
  }
  .page-template-template-equipe #page-intro #page-intro-sous-titre {
    font-size: 36px;
  }
  .single #single-intro {
    height: 450px;
  }
  #newsletter .container-newsletter .frm_style_style-formidable .frm_form_field {
    width: 75%;
  }
}
@media only screen and (max-width: 1024px) {
  #nav-main {
    width: 100%;
  }
  #page-intro-titre {
    font-size: 90px;
  }
  #page-intro #page-intro-sous-titre {
    font-size: 44px;
  }
  .page-template-template-equipe #page-intro #page-intro-titre {
    font-size: 70px;
    right: 20%;
  }
  .page-template-template-equipe #page-intro #page-intro-sous-titre {
    font-size: 32px;
  }
  .page-template-template-services a:hover {
    display: flex;
  }
  .page-template-template-services a:hover .hover-thumbnail {
    display: none;
  }
  #newsletter .container-newsletter .frm_style_style-formidable .frm_form_field {
    width: 74%;
  }
}
@media only screen and (max-width: 992px) {
  #nav-main {
    width: 100%;
  }
  h1 {
    font-size: 90px;
    line-height: 82px;
  }
  h2 {
    font-size: 60px;
    line-height: 52px;
  }
  h3 {
    font-size: 38px;
  }
  .section-row h3 {
    margin-bottom: 0;
  }
  .projet-colore {
    margin-bottom: 15px;
  }
  .projet-colore a {
    padding: 30px;
  }
  .home .actu .wp-post-image {
    width: 100%;
  }
  #home-actualites .actu img {
    width: 100%;
    object-fit: cover;
    height: 320px;
  }
  #page-intro {
    height: auto;
    padding: 40% 0;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }
  #page-intro #page-intro-titre {
    left: 0 !important;
    right: 0 !important;
  }
  #page-intro #page-intro-sous-titre {
    font-size: 40px;
    line-height: 38px;
    left: 0 !important;
    right: 0 !important;
  }
  #scroll-icon {
    bottom: 30px;
    display: none;
  }
  .vagues-4-jaune {
    margin: 40px auto 30px;
  }
  .section-row h2:after {
    content: none;
  }
  .section-row h3 {
    right: 0;
  }
  .page-template-template-metiers #page-intro #c-blanc {
    top: -17%;
    left: -2%;
    width: 80%;
  }
  .page-template-template-metiers #page-intro #c-noir-small {
    top: 45px;
    right: 30px;
    width: 30px;
  }
  .page-template-template-metiers #page-intro #vagues-jaune {
    top: 75%;
    left: -5%;
    width: 55%;
    height: 150px;
  }
  .page-template-template-metiers #page-intro #vagues-noir {
    right: 60px;
    bottom: 60px;
    width: 20%;
  }
  .page-template-template-adn #page-intro #c-blanc {
    width: 80%;
    left: 40%;
    top: 24%;
  }
  .page-template-template-adn #page-intro #c-jaune-small {
    top: 45px;
    right: 30px;
    width: 30px;
  }
  .page-template-template-adn #page-intro #vagues-blanc {
    top: 20%;
    left: -15%;
    width: 60%;
    opacity: 0.07;
  }
  .page-template-template-adn #page-intro #vagues-jaune {
    left: 20%;
  }
  .page-template-template-projets #page-intro #c-blanc,
  .post-type-archive-projets #page-intro #c-blanc {
    width: 70%;
    top: 6%;
  }
  .page-template-template-projets #page-intro #vagues-jaune,
  .post-type-archive-projets #page-intro #vagues-jaune {
    z-index: 6;
    left: 5%;
    width: 90%;
    height: 150px;
  }
  .page-template-template-projets #page-intro #vagues-noir,
  .post-type-archive-projets #page-intro #vagues-noir {
    width: 20%;
  }
  .page-template-template-projets #page-intro #c-noir-small,
  .post-type-archive-projets #page-intro #c-noir-small {
    width: 40px;
    top: 8%;
  }
  .page-template-template-projets #page-intro #virgule-gris,
  .post-type-archive-projets #page-intro #virgule-gris {
    top: 6%;
    right: 6%;
    width: 70px;
  }
  .projet .projet-infos .projet-desc {
    margin-top: 40px;
  }
  .projet .projet-image {
    height: 600px;
  }
  .texte_seul {
    width: 60%;
  }
  .page-template-template-equipe #photo-equipe,
  .page-template-template-equipe .pin {
    display: none;
  }
  .page-template-template-equipe #page-intro {
    height: 100%;
    padding: 120px 15px;
  }
  .page-template-template-equipe #page-intro #page-intro-titre {
    position: relative;
    top: 0;
  }
  .page-template-template-equipe #page-intro #page-intro-sous-titre {
    text-align: center;
    width: auto;
    position: relative;
    top: 0;
  }
  .page-template-template-equipe #page-intro #c-blanc {
    width: 80%;
    top: 10%;
    left: 10%;
  }
  .page-template-template-equipe #page-intro #vagues-jaune {
    top: 20%;
    left: -5%;
    width: 60%;
    z-index: 2;
    height: 150px;
  }
  .page-template-template-equipe #page-intro #vagues-noir {
    width: 20%;
    left: 0;
  }
  .page-template-template-equipe #page-intro #c-noir-small {
    width: 40px;
    right: 30px;
    bottom: 30px;
  }
  .page-template-template-equipe #page-intro #virgule-gris {
    top: 6%;
    right: 6%;
    width: 70px;
  }
  .page-template-template-equipe #presentation-equipe .glide__slide {
    display: flex;
    flex-direction: column-reverse;
  }
  .page-template-template-equipe #presentation-equipe .infos-salarie {
    width: 100%;
    height: auto;
    float: right;
  }
  .page-template-template-equipe #presentation-equipe .photo-salarie {
    width: 100%;
    float: right;
  }
  #construction {
    display: none;
  }
  .membre-metiers {
    font-size: 34px;
  }
  .membre-texte img,
  .membre-texte picture {
    width: 150px;
    margin: 0;
  }
  .membre-prenom {
    margin-bottom: 30px;
  }
  .membre-photo {
    margin-bottom: 30px;
  }
  .membre-photo img,
  .membre-photo picture {
    width: 100%;
  }
  .page-template-template-actualites #page-intro #c-blanc {
    width: 70%;
    top: 0;
  }
  #actu-principale img,
  #actu-principale picture {
    width: 100%;
    margin-bottom: 30px;
  }
  .single #single-intro {
    height: 330px;
  }
  #nav-fin-projets .nav-fin-projet {
    margin-bottom: 30px;
  }
  #nav-fin-projets .nav-fin-projet img {
    height: 320px;
    width: 100%;
    object-fit: cover;
  }
  #nav-fin-projets .nav-fin-projet .nav-fin-projet-titre {
    margin-top: 20px;
  }
  .page-id-138 #page-intro {
    background-color: var(--jaunec);
  }
  .page-id-138 #page-intro #c-blanc {
    width: 80%;
    top: 10%;
    left: 10%;
  }
  .page-id-138 #page-intro #vagues-jaune {
    top: 80%;
    left: -5%;
    width: 60%;
    z-index: 2;
    height: 150px;
  }
  .page-id-138 #page-intro #vagues-noir {
    width: 20%;
    left: 0;
  }
  .page-id-138 #page-intro #c-noir-small {
    width: 40px;
    right: 30px;
    bottom: 30px;
  }
  .page-id-138 .page-content {
    padding-top: 120px;
    padding-bottom: 90px;
  }
  .page-id-138 .page-content h2 {
    font-size: 70px;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .page-id-138 .page-content h3 {
    font-size: 38px;
  }
  .page-id-138 .page-content ul li {
    font-family: "Gotham-Light";
  }
  #contact-questions h2,
  #contact-candidature h2 {
    right: auto;
  }
  .section-row:nth-child(odd) h2,
  .section-row:nth-child(odd) h3 {
    text-align: left;
  }
  .section-row {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .page-template-template-contact #form_formulairedecontact .frm_checkbox {
    width: 100%;
  }
  .page-template-template-contact #form_formulairedecontact .frm_checkbox label {
    padding: 15px 10px;
  }
  .page-template-template-contact #form_formulairedecontact .frm_submit button:after {
    content: none;
  }
  #footer-cta {
    display: none;
  }
  #newsletter .container-newsletter .frm_style_style-formidable .frm_fields_container {
    display: block;
  }
  #newsletter .container-newsletter .frm_style_style-formidable .frm_form_field {
    width: 100%;
  }
  #newsletter .container-newsletter .frm_style_style-formidable .frm_submit button {
    width: 100%;
  }
}
@media only screen and (max-height: 870px) {
  #nav-main {
    padding: 15px 100px;
    min-height: calc(100% - 60px);
    height: calc(100vh - 60px);
  }
  #nav-main #menu-menu-principal li {
    padding: 20px;
  }
  #nav-main #menu-menu-principal li a {
    font-size: 40px;
  }
  #nav-main #menu-menu-principal li a .main-nav-description {
    display: none;
  }
  #nav-main #nav-second {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 768px) {
  #nav-main {
    padding: 30px 30px;
    min-height: calc(100% - 60px);
  }
  #nav-main #menu-menu-principal li {
    padding: 20px;
  }
  #nav-main #menu-menu-principal li a {
    font-size: 40px;
  }
  #nav-main #menu-menu-principal li a .main-nav-description {
    display: none;
  }
  #nav-main #nav-second {
    padding-left: 20px;
  }
  #nav-main .container-equipe .content-image-equipe {
    display: none;
  }
  #header-logo {
    margin-left: 30px;
  }
  #header-hamburger {
    padding-right: 30px;
  }
  body {
    border-right: 30px solid var(--blanc);
    border-left: 30px solid var(--blanc);
  }
  #progress {
    margin-left: 30px;
    max-width: calc(100% - 60px);
  }
  h1,
  #page-intro-titre {
    font-size: 70px;
    line-height: 62px;
  }
  h2 {
    font-size: 50px;
    line-height: 42px;
  }
  h3 {
    font-size: 38px;
  }
  .home #virgule {
    height: auto;
  }
  .single-post blockquote,
  .single-post h3,
  .single-post iframe,
  .single-post ol,
  .single-post p,
  .single-post ul {
    margin-left: 0;
    margin-right: 0;
  }
  .page-template-template-equipe #page-intro {
    width: calc(100vw - 60px);
  }
  .page-template-template-equipe #presentation-equipe {
    width: calc(100vw - 60px);
  }
  .single #single-projets-titre {
    height: auto;
  }
  .single #single-projets-titre h1 {
    margin-top: 45px;
    font-size: 36px;
    line-height: 38px;
  }
  .single #single-projets-titre #scroll-icon {
    margin-bottom: 45px;
  }
  .single .single-container h1 {
    font-size: 35px;
    line-height: 37px;
    margin-left: 0;
  }
  .single-post h2 {
    margin-left: 0;
  }
  .single-post ul.post-categories {
    padding: 0;
    display: block;
    margin: 20px 0 0;
  }
  .single-post ul.post-categories li {
    display: block;
  }
  .single-post .btn-jaune:after {
    display: none;
  }
  .prestation-item {
    padding-left: 15px;
  }
  #home-clients ul li {
    width: 50%;
  }
  #home-clients ul li:nth-child(9) {
    display: none;
  }
  .projets-colores #projets-deco-2 {
    left: 10%;
  }
  .home .overlay {
    width: calc(100vw - 30px);
  }
  #home-hero-video {
    width: calc(100vw - 30px);
    height: calc(100vh - 30px);
  }
  .error404 #section404 {
    width: 100%;
  }
  #actualites .actu-multiple .actu-image img {
    height: 320px;
    width: 100%;
    object-fit: cover;
  }
  .main-footer-expand {
    padding-bottom: 160px;
  }
  .single #single-nav ul li a {
    padding: 25px;
  }
  .single #single-nav ul li #single-nav-prev svg,
  .single #single-nav ul li #single-nav-next svg {
    display: none;
  }
  .projet .projet-image {
    height: 400px;
  }
}
@media only screen and (max-width: 640px) {
  #home-caption {
    padding: 0 10%;
  }
  #home-caption p {
    font-size: 60px;
    line-height: 62px;
  }
  #home-caption h1  {
    font-size: 34px;
    line-height: 28px;
  }
  .page-content {
    margin-bottom: 240px;
  }
  .projet .projet-image {
    height: 350px;
  }
  footer.footer-expand {
    padding-bottom: 60px;
  }
  footer.footer-expand #footer-cta {
    text-align: center;
    padding-left: 0;
    display: block;
  }
  footer #footer-social {
    position: relative;
    text-align: center;
    right: auto;
    bottom: auto;
  }
  .page-template-template-equipe #presentation-equipe {
    margin-bottom: 60px;
  }
  .page-template-template-equipe #presentation-equipe h3 {
    font-size: 48px;
  }
  .page-template-template-equipe #presentation-equipe .glide__arrows {
    display: none;
  }
  .page-template-template-equipe #presentation-equipe .vagues-4-blanc {
    display: none;
  }
  .page-template-template-equipe #presentation-equipe .glide__slide {
    display: flex;
    flex-direction: column-reverse;
  }
  .page-template-template-equipe #presentation-equipe .infos-salarie {
    padding: 90px 30px;
  }
  .page-template-template-equipe #presentation-equipe .photo-salarie {
    width: 100%;
    height: 400px;
  }
  .page-template-template-candidature #page-intro-titre,
  .page-template-template-candidature h1 {
    font-size: 60px;
    word-break: break-all;
  }
  .page-template-template-candidature #page-intro #page-intro-titre:after {
    display: none;
  }
  footer #theme_switch {
    padding-left: 40px;
  }
  footer label {
    width: 57px;
    height: 34px;
  }
  footer .fa-moon,
  footer .fa-sun {
    width: 20px;
    height: 20px;
  }
  footer .ball {
    width: 25px;
    height: 25px;
    top: 4px;
    left: 4px;
  }
}
@media only screen and (max-width: 541px) {
  .services-template-default.single-services #page-intro-titre {
    font-size: 55px;
  }
  #nav-main .container-equipe .content-nav {
    padding: 30px 15px;
  }
  #nav-main .container-contact,
  #nav-main .container-recrutement {
    padding: 30px 15px;
  }
  .single #single-intro {
    height: 250px;
  }
  .main-telephone {
    display: none;
  }
  .hamburger {
    display: block;
    margin-top: -6px;
  }
}
@media only screen and (max-width: 425px) {
  #header-logo {
    margin-left: 15px;
  }
  #header-logo img,
  #header-logo picture {
    max-width: 125px;
  }
  #header-hamburger {
    padding-right: 15px;
  }
  #nav-main {
    padding: 15px;
  }
  #nav-main #menu-menu-principal li {
    padding: 20px;
  }
  #nav-main #menu-menu-principal li a {
    font-size: 34px;
  }
  .home main {
    padding-bottom: 220px;
  }
  .home #virgule {
    width: 100%;
    height: auto;
    right: 0;
    top: 15%;
  }
  #actu-principale .actu-titre {
    font-size: 44px;
    line-height: 46px;
  }
  .actu .actu-titre {
    font-size: 32px;
    line-height: 36px;
  }
  #actualites-filtres ul li {
    display: block;
    text-align: left;
  }
  .projet .projet-infos .projet-date {
    font-size: 26px;
  }
  .projet .projet-infos .projet-titre {
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  .projet .projet-infos .projet-desc {
    margin-top: 40px;
  }
  .projet .projet-infos .projet-desc p {
    font-size: 16px;
    line-height: 22px;
  }
  .projet .projet-infos .projet-competences li {
    font-size: 15px;
  }
  #page-intro-titre {
    font-size: 60px;
    line-height: 52px;
  }
  #page-intro #page-intro-sous-titre,
  h3 {
    font-size: 30px;
    line-height: 34px;
  }
  .page-cta {
    font-size: 30px;
  }
  .single .single-container h1 {
    font-size: 28px;
    line-height: 30px;
  }
  #home-clients ul li {
    width: 100%;
  }
  #home-clients ul li:nth-child(9) {
    display: flex;
  }
  .services-template-default.single-services #page-intro-titre {
    font-size: 45px;
    line-height: 35px;
  }
  .services-template-default.single-services.postid-919 #page-intro #page-intro-sous-titre, .services-template-default.single-services.postid-893 #page-intro #page-intro-sous-titre, .services-template-default.single-services.postid-920 #page-intro #page-intro-sous-titre, .services-template-default.single-services.postid-900 #page-intro #page-intro-sous-titre {
    font-size: 25px;
    text-align: left;
  }
  .single #single-nav ul {
    display: block;
  }
  .single #single-nav ul li {
    display: block;
  }
}
@media only screen and (max-width: 641px) {
  .home main {
    padding-bottom: 220px;
  }
  #home-caption p:after {
    display: none;
  }
}
@media only screen and (max-width: 541px) {
  .btn-jaune a {
    display: block;
  }
}
@media only screen and (max-width: 481px) {
  #progress {
    margin-left: 0px;
    max-width: calc(100% - 0px);
  }
  body {
    border-left: 0;
    border-right: 0;
  }
  section {
    padding-left: 0;
    padding-right: 0;
  }
  #home-hero-video,
  .home .overlay {
    width: 100%;
  }
  .home main {
    padding-bottom: 220px;
  }
}
@media only screen and (max-width: 421px) {
  footer #theme_switch {
    padding-left: 10px;
  }
}
@media only screen and (max-width: 375px) {
  body {
    border-left: 0;
    border-right: 0;
  }
  section {
    padding-left: 0;
    padding-right: 0;
  }
  #home-hero-video {
    width: 100%;
    height: 100%;
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .home .overlay {
    width: 100%;
  }
  h2 {
    font-size: 40px;
    line-height: 32px;
  }
  #page-intro #page-intro-sous-titre,
  h3 {
    font-size: 22px;
    line-height: 24px;
  }
  #currentTime {
    margin-bottom: 10px;
  }
  #home-caption h1 {
    font-size: 28px;
  }
  #home-caption p {
    font-size: 46px;
    line-height: 46px;
  }
  .page-template-template-equipe #page-intro,
  .page-template-template-equipe #presentation-equipe {
    width: 100vw;
  }
}
#form_calendriermarketing {
  margin-left: 12%;
  margin-right: 12%;
}

#form_calendriermarketing input[type=text],
#form_calendriermarketing input[type=tel],
#form_calendriermarketing input[type=email],
#form_calendriermarketing input[type=url],
#form_candidaturespontane input[type=text],
#form_candidaturespontane input[type=tel],
#form_candidaturespontane input[type=email],
#form_candidaturespontane input[type=url],
#form_candidatureweb input[type=text],
#form_candidatureweb input[type=tel],
#form_candidatureweb input[type=email],
#form_candidatureweb input[type=url] {
  background-color: var(--grisf);
  height: 55px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
  font-family: "Gotham-Light";
}
#form_calendriermarketing textarea,
#form_candidaturespontane textarea,
#form_candidatureweb textarea {
  background-color: var(--grisf);
  min-height: 295px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
#form_calendriermarketing #frm_field_12_container,
#form_candidaturespontane #frm_field_12_container,
#form_candidatureweb #frm_field_12_container {
  padding-top: 15px;
}
#form_calendriermarketing .frm_primary_label,
#form_candidaturespontane .frm_primary_label,
#form_candidatureweb .frm_primary_label {
  font-family: "Gotham-Bold";
  font-size: 16px;
  color: var(--noir);
}
#form_calendriermarketing .frm_checkbox,
#form_candidaturespontane .frm_checkbox,
#form_candidatureweb .frm_checkbox {
  float: left;
  width: 20%;
  text-align: center;
  border: 1px solid var(--grisc);
}
#form_calendriermarketing .frm_checkbox label,
#form_candidaturespontane .frm_checkbox label,
#form_candidatureweb .frm_checkbox label {
  padding: 20px 10px;
  text-transform: uppercase;
  font-family: "Gotham-Light";
  font-size: 14px;
  background-color: var(--grisf);
  margin-bottom: 0;
  cursor: pointer;
  transition: all 0.35s linear;
}
#form_calendriermarketing .frm_checkbox input,
#form_candidaturespontane .frm_checkbox input,
#form_candidatureweb .frm_checkbox input {
  opacity: 0;
}
#form_calendriermarketing .checked,
#form_candidaturespontane .checked,
#form_candidatureweb .checked {
  background-color: var(--jaunec) !important;
  transition: all 0.35s linear;
}
#form_calendriermarketing .frm_dropzone .dz-message,
#form_candidaturespontane .frm_dropzone .dz-message,
#form_candidatureweb .frm_dropzone .dz-message {
  background-color: var(--grisf);
  margin-bottom: 0;
}
#form_calendriermarketing .frm_submit,
#form_candidaturespontane .frm_submit,
#form_candidatureweb .frm_submit {
  text-align: right;
}
#form_calendriermarketing .frm_submit button,
#form_candidaturespontane .frm_submit button,
#form_candidatureweb .frm_submit button {
  border: 2px solid var(--jaunec);
  background-color: var(--jaunec);
  border-radius: 0;
  font-family: "Gotham-Bold";
  font-size: 15px;
  text-transform: uppercase;
  color: #000;
  padding: 20px;
  letter-spacing: 1.5px;
  transition: all 0.35s linear;
  position: relative;
}
#form_calendriermarketing .frm_submit button:after,
#form_candidaturespontane .frm_submit button:after,
#form_candidatureweb .frm_submit button:after {
  content: "";
  background-size: contain;
  background-image: url("../img/fleche-scroll-blanc.svg");
  transform: rotate(-90deg);
  width: 18px;
  display: inline-block;
  margin-left: 40px;
}
#form_calendriermarketing .frm_submit button:hover,
#form_candidaturespontane .frm_submit button:hover,
#form_candidatureweb .frm_submit button:hover {
  background-color: #fff;
  transition: all 0.35s linear;
}
#form_calendriermarketing .frm_submit button:hover:after,
#form_candidaturespontane .frm_submit button:hover:after,
#form_candidatureweb .frm_submit button:hover:after {
  background-image: url("../img/fleche-scroll-jaune.svg");
}

#form_candidaturegraphiste input[type=text],
#form_candidaturegraphiste input[type=tel],
#form_candidaturegraphiste input[type=email],
#form_candidaturegraphiste input[type=url] {
  background-color: var(--grisf);
  height: 55px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
  font-family: "Gotham-Light";
}
#form_candidaturegraphiste textarea {
  background-color: var(--grisf);
  min-height: 295px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
#form_candidaturegraphiste #frm_field_12_container {
  padding-top: 15px;
}
#form_candidaturegraphiste .frm_primary_label {
  font-family: "Gotham-Bold";
  font-size: 16px;
  color: var(--noir);
}
#form_candidaturegraphiste .checked {
  background-color: var(--jaunec) !important;
  transition: all 0.35s linear;
}
#form_candidaturegraphiste .frm_submit {
  text-align: right;
}
#form_candidaturegraphiste .frm_submit button {
  border: 2px solid var(--jaunec);
  background-color: var(--jaunec);
  border-radius: 0;
  font-family: "Gotham-Bold";
  font-size: 15px;
  text-transform: uppercase;
  color: #000;
  padding: 20px;
  letter-spacing: 1.5px;
  transition: all 0.35s linear;
  position: relative;
}
#form_candidaturegraphiste .frm_submit button:after {
  content: "";
  background-size: contain;
  background-image: url("../img/fleche-scroll-blanc.svg");
  transform: rotate(-90deg);
  width: 18px;
  display: inline-block;
  margin-left: 40px;
}
#form_candidaturegraphiste .frm_submit button:hover {
  background-color: #fff;
  transition: all 0.35s linear;
}
#form_candidaturegraphiste .frm_submit button:hover:after {
  background-image: url("../img/fleche-scroll-jaune.svg");
}

#myModal {
  z-index: 9999;
}
#myModal .modal-dialog {
  max-width: 720px;
}
#myModal .modal-content {
  border: 6px solid var(--jaunec);
  background-color: var(--blanc);
}
#myModal .modal-content h2 {
  font-size: 40px;
  font-family: "Gotham-Black";
  color: var(--noir);
}
#myModal .modal-content svg {
  position: absolute;
  top: -10px;
  right: -10px;
  margin: 0;
  height: 30px;
  width: 30px;
}
#myModal .modal-content svg path,
#myModal .modal-content svg circle {
  fill: var(--noir);
}
#myModal .modal-content svg path,
#myModal .modal-content svg circle {
  stroke: var(--noir);
}
#myModal .modal-content #close {
  cursor: pointer;
}
#myModal .modal-content .close {
  background-color: transparent;
  border: none;
  position: relative;
}
#myModal .modal-content .close span {
  display: block;
  position: relative;
  font-size: 16px;
}