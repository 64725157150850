html[data-theme="dark"] {
  body {
    ::after,
    ::before {
      filter: brightness(0.8) contrast(1.2);
    }
    blockquote:after,
    blockquote:before {
      filter: none;
    }
    img,
    #single-intro,
    #currentTime,
    .actu .post-categories li a {
      filter: brightness(0.8) contrast(1.2);
    }
    .overlay {
      opacity: 0.5;
    }
    .home #virgule {
      opacity: 0.22;
    }
    // HEADER
    header {
      .logo-darkmode {
        display: block;
      }
      video {
        display: none;
      }
    }
    .container-contact {
      #menu-menu-principal {
        .main-telephone {
          svg {
            #phone-alt-solid {
              fill: #000;
            }
          }
        }
      }
    }
    .projet-colore {
      filter: brightness(0.8) contrast(1.2);
    }
    .projets-colores {
      #projets-deco-1:before,
      #projets-deco-2:after {
        background-image: url(../../assets/img/fleche-gauche-darkmode.svg);
      }
    }
    #contact-questions:before,
    #contact-candidature:before,
    #contact-projets:before {
      opacity: 0.1;
    }
    &.page-template-template-caroule {
      .feedbackbox {
        #frm_form_6_container {
          #frm_field_46_container {
            .frm-star-group {
              .star-rating:before {
                content: "";
                background-image: url("../img/virgule-feedback-darkmode.svg");
                opacity: 0.4;
              }
              .star-rating-on:before {
                content: "";
                background-image: url("../img/virgule-jaune-feedback-darkmode.svg");
                opacity: 0.9;
              }
            }
          }
        }
      }
    }
    &.page-template-template-projets {
      .projet {
        filter: brightness(0.8) contrast(1.2);
      }
    }

    &.page-template,
    &.page-template-default {
      #page-intro {
        background-color: var(--grisf);
        #c-blanc {
          opacity: 0.1;
        }
        #vagues-jaune {
          opacity: 0.8;
        }
        #page-intro-titre {
          &:after {
            filter: brightness(0.8) contrast(1.2);
            content: "";
            background-image: url(../../assets/img/virgule-jaune.svg);
          }
        }
      }
      #presentation-equipe {
        filter: brightness(0.8) contrast(1.2);
      }
    }
    &.services-template-default.single-services {
      #page-intro {
        &.bg-yellow,
        &.bg-black {
          background-color: var(--grisf);
          #c-blanc {
            opacity: 0.1;
          }
          #vagues-jaune {
            opacity: 0.8;
          }
        }
      }
    }

    &.postid-518 {
      section:nth-child(12) {
        background-color: #000 !important;
      }
    }
  } // Fin Body
}

@media (prefers-color-scheme: dark) {
  body {
    ::after,
    ::before {
      filter: brightness(0.8) contrast(1.2);
    }
    blockquote:after,
    blockquote:before {
      filter: none;
    }
    img,
    #single-intro,
    #currentTime,
    .actu .post-categories li a {
      filter: brightness(0.8) contrast(1.2);
    }
    .overlay {
      opacity: 0.5;
    }
    .home #virgule {
      opacity: 0.22;
    }
    // HEADER
    header {
      .logo-darkmode {
        display: block;
      }
      video {
        display: none;
      }
    }
    .container-contact {
      #menu-menu-principal {
        .main-telephone {
          svg {
            #phone-alt-solid {
              fill: #000;
            }
          }
        }
      }
    }
    .projet-colore {
      filter: brightness(0.8) contrast(1.2);
    }
    .projets-colores {
      #projets-deco-1:before,
      #projets-deco-2:after {
        background-image: url(../../assets/img/fleche-gauche-darkmode.svg);
      }
    }
    #contact-questions:before,
    #contact-candidature:before,
    #contact-projets:before {
      opacity: 0.1;
    }

    &.page-template-template-caroule {
      .feedbackbox {
        #frm_form_6_container {
          #frm_field_46_container {
            .frm-star-group {
              .star-rating:before {
                content: "";
                background-image: url("../img/virgule-feedback-darkmode.svg");
                opacity: 0.4;
              }
              .star-rating-on:before {
                content: "";
                background-image: url("../img/virgule-jaune-feedback-darkmode.svg");
                opacity: 0.9;
              }
            }
          }
        }
      }
    }
    &.page-template-template-projets {
      .projet {
        filter: brightness(0.8) contrast(1.2);
      }
    }

    &.page-template,
    &.page-template-default {
      #page-intro {
        background-color: var(--grisf);
        #c-blanc {
          opacity: 0.1;
        }
        #vagues-jaune {
          opacity: 0.8;
        }
        #page-intro-titre {
          &:after {
            filter: brightness(0.8) contrast(1.2);
            content: url(../../assets/img/virgule-jaune.svg);
          }
        }
      }
      #presentation-equipe {
        filter: brightness(0.8) contrast(1.2);
      }
    }
    &.services-template-default.single-services {
      #page-intro {
        &.bg-yellow,
        &.bg-black {
          background-color: var(--grisf);
          #c-blanc {
            opacity: 0.1;
          }
          #vagues-jaune {
            opacity: 0.8;
          }
        }
      }
    }

    &.postid-518 {
      section:nth-child(12) {
        background-color: #000 !important;
      }
    }
  } //Fin body
}
