@import "./darkmode.scss";

// Typekit

// Fonts
@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/8de680/0000000000000000000132c1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/8de680/0000000000000000000132c1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/8de680/0000000000000000000132c1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/483ddc/0000000000000000000132c9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/483ddc/0000000000000000000132c9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/483ddc/0000000000000000000132c9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: "Gotham-Black";
  src: url("../fonts/gotham/black/gotham-black-webfont.eot");
  src: url("../fonts/gotham/black/gotham-black-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/gotham/black/gotham-black-webfont.woff") format("woff"),
    url("../fonts/gotham/black/gotham-black-webfont.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Bold";
  src: url("../fonts/gotham/bold/gotham-bold-webfont.eot");
  src: url("../fonts/gotham/bold/gotham-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/gotham/bold/gotham-bold-webfont.woff2") format("woff2"),
    url("../fonts/gotham/bold/gotham-bold-webfont.woff") format("woff"),
    url("../fonts/gotham/bold/gotham-bold-webfont.ttf") format("truetype"),
    url("../fonts/gotham/bold/gotham-bold-webfont.svg#gotham_boldregular")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Gotham-Light";
  src: url("../fonts/gotham/light/gotham-light-webfont.eot");
  src: url("../fonts/gotham/light/gotham-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/gotham/light/gotham-light-webfont.woff2") format("woff2"),
    url("../fonts/gotham/light/gotham-light-webfont.woff") format("woff"),
    url("../fonts/gotham/light/gotham-light-webfont.ttf") format("truetype"),
    url("../fonts/gotham/light/gotham-light-webfont.svg#gotham_lightregular")
      format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Gotham-Light-Italic";
  src: url("../fonts/gotham/light/gotham-light-italic-webfont.eot");
  src: url("../fonts/gotham/light/gotham-light-italic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/gotham/light/gotham-light-italic-webfont.woff2")
      format("woff2"),
    url("../fonts/gotham/light/gotham-light-italic-webfont.woff") format("woff"),
    url("../fonts/gotham/light/gotham-light-italic-webfont.ttf")
      format("truetype"),
    url("../fonts/gotham/light/gotham-light-italic-webfont.svg#gotham_lightitalic")
      format("svg");
  font-display: swap;
}

$gbl: "Gotham-Black";
$gbo: "Gotham-Bold";
$gl: "Gotham-Light";
$gli: "Gotham-Light-Italic";
$fbp: "freight-big-pro", serif;

// Couleurs
:root {
  color-scheme: light dark;
  --jaunec: #ffee35;
  --jaunef: #ffdf05;
  --noir: #000000;
  --grisc: #f8f8f8;
  --grisf: #edecec;
  --grisfm: #989898;
  --blanc: #fff;
}

html[data-theme="light"] {
  --jaunec: #ffee35;
  --jaunef: #ffdf05;
  --noir: #000000;
  --grisc: #f8f8f8;
  --grisf: #edecec;
  --blanc: #fff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --jaunec: #ffee35;
    --jaunef: #f8ea3e;
    --noir: #fff;
    --grisc: #1c1c1c;
    --grisf: #121212;
    --grisfm: #fff;
    --blanc: #000;
  }
  html[data-theme="light"] {
    --jaunec: #ffee35;
    --jaunef: #ffdf05;
    --noir: #000000;
    --grisc: #f8f8f8;
    --grisf: #edecec;
    --grisfm: #989898;
    --blanc: #fff;
  }
}
html[data-theme="dark"] {
  --jaunec: #ffee35;
  --jaunef: #f8ea3e;
  --noir: #fff;
  --grisc: #1c1c1c;
  --grisf: #121212;
  --grisfm: #fff;
  --blanc: #000;
}

// DATA THEME LIGHT
html[data-theme="light"] {
  body {
    ::after,
    ::before {
      filter: none;
    }
    img,
    #single-intro,
    #currentTime,
    .actu .post-categories li a {
      filter: none;
    }
    .overlay {
      opacity: 0.2;
    }
    .home #virgule {
      opacity: 0.33;
    }
    // HEADER
    header {
      .logo-darkmode {
        display: none;
      }
      video {
        display: block;
      }
    }
    .projet-colore {
      filter: none;
    }
    .projets-colores {
      #projets-deco-1:before,
      #projets-deco-2:after {
        background-image: url(../../assets/img/fleche-gauche.svg);
      }
    }
    #contact-questions:before,
    #contact-candidature:before,
    #contact-projets:before {
      opacity: 1;
    }
    &.page-template-template-caroule {
      .feedbackbox {
        #frm_form_6_container {
          #frm_field_46_container {
            .frm-star-group {
              .star-rating:before {
                content: url("../img/virgule-feedback.svg");
                opacity: 1;
              }
              .star-rating-on:before {
                content: url("../img/virgule-jaune-feedback.svg");
                opacity: 1;
              }
            }
          }
        }
      }
    }
    &.page-template-template-projets {
      .projet {
        filter: none;
      }
    }

    &.page-template-template-adn #page-intro,
    &.page-template-template-candidature #page-intro,
    &.page-template-template-caroule #page-intro,
    &.page-template-template-candidature-graphiste #page-intro,
    &.page-template-template-candidature-web #page-intro {
      background-color: #000;
      #c-blanc {
        opacity: 0.1 !important;
      }
      #vagues-jaune {
        opacity: 1;
      }
    }
    &.page-template-template-metiers #page-intro {
      background-color: var(--jaunec);
      #c-blanc {
        opacity: 1;
      }
      #vagues-jaune {
        opacity: 1;
      }
    }
    &.page-template-template-affiche #page-intro,
    &.page-template-template-projets #page-intro,
    &.post-type-archive-projets #page-intro {
      background-color: var(--grisc);
      #c-blanc {
        opacity: 1;
      }
      #vagues-jaune {
        opacity: 1;
      }
    }
    &.services-template-default.single-services {
      #page-intro {
        &.bg-black {
          background-color: var(--noir);
          #c-blanc {
            opacity: 1;
          }
          #vagues-jaune {
            opacity: 1;
          }
        }
        &.bg-yellow {
          background-color: var(--jaunec);
          #c-blanc {
            opacity: 1;
          }
          #vagues-jaune {
            opacity: 1;
          }
        }
      }
    }
  } // Fin Body
}

// Animations
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

// Embed container
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.wp-caption.aligncenter {
  margin: 0 auto;
}

// Basic
.ux {
  max-width: 1440px;
  margin: 0 auto;
}

.bold {
  font-weight: bold;
  font-family: $gbo;
}

html {
  overflow-x: hidden;
}

body {
  color: var(--noir);
  background-color: var(--grisc);
  min-height: 100vh;
  border-left: 60px solid var(--blanc);
  border-right: 60px solid var(--blanc);
  padding-bottom: 60px;
  &.remove-scrolling {
    overflow: hidden;
  }
}

img,
picture {
  max-width: 100%;
  height: auto;
}

svg {
  path,
  rect {
    fill: var(--noir);
  }
}

.main-footer-expand {
  padding-bottom: 60px;
}

#progress {
  background-color: var(--jaunec);
  height: 3px;
  width: 0;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 99999 !important; /* Peut être important si vous avez un menu en position fixe */
  margin-left: 60px;
  max-width: calc(100% - 120px);
}

#home-hero-video {
  position: relative;
  width: calc(100vw - 120px);
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  margin-bottom: 120px;
}
.video-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
}
.overlay {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
}
.home .overlay {
  width: calc(100vw - 120px);
}
.section-row {
  padding-top: 150px;
  padding-bottom: 150px;
  &:nth-child(odd) {
    h2,
    h3 {
      display: block;
      text-align: right;
    }
  }
  h2 {
    margin-bottom: 0;
    position: relative;
    &:after {
      background-image: url(../../assets/img/virgule-jaune.svg);
      content: "";
      background-size: contain;
      width: 40px;
      height: 40px;
      display: block;
      position: absolute;
      right: -40px;
      bottom: -20px;
    }
  }
  h3 {
    margin-bottom: 70px;
    position: relative;
    right: 60px;
    margin-top: 10px;
  }
}
.vagues-4-jaune {
  width: 145px;
  height: auto;
  margin-bottom: 60px;
}

// Typographie
h1 {
  font-family: $gbl;
  font-size: 110px;
  line-height: 90px;
  letter-spacing: -2px;
}
h2 {
  font-family: $gbo;
  font-size: 60px;
  line-height: 54px;
  display: inline-block;
  position: relative;
  letter-spacing: -2px;
}
h3 {
  font-family: $fbp;
  font-size: 36px;
}
p,
ul li,
ol li {
  font-family: $gl;
  font-size: 16px;
  line-height: 30px;
  color: var(--noir);
}
strong {
  font-family: $gbo;
  font-weight: normal;
}
a {
  color: var(--noir);
  transition: color 0.5s ease;
  &:hover {
    color: var(--jaunef);
    text-decoration: none;
    transition: color 0.5s ease;
  }
}

blockquote {
  font-family: $gli;
  font-size: 19px;
  color: var(--noir);
  padding: 30px 50px;
  margin: 45px auto;
  position: relative;
  border: 1px solid var(--jaunec);
  &:before {
    content: open-quote;
    color: var(--jaunec);
    background-color: var(--grisc);
    width: 60px;
    height: 60px;
    position: absolute;
    top: 15%;
    left: -30px;
    font-size: 100px;
    line-height: 1;
  }
  &:after {
    content: close-quote;
    color: var(--jaunec);
    background-color: var(--grisc);
    width: 60px;
    height: 60px;
    position: absolute;
    top: 55%;
    right: -30px;
    font-size: 100px;
    line-height: 1;
  }
  p {
    margin-bottom: 0;
  }
}

// Boutons
.btn-jaune {
  a {
    border: 2px solid var(--jaunec);
    background-color: var(--jaunec);
    border-radius: 0;
    font-family: $gbo;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    transition: all 0.35s linear;
    width: auto;
    max-width: 100%;
    border-radius: 0;
    padding: 20px;
    color: #000;
    &:after {
      content: "";
      background-size: contain;
      background-image: url("../img/fleche-scroll-blanc.svg");
      transform: rotate(-90deg);
      width: 18px;
      display: inline-block;
      margin-left: 20px;
    }
    &:hover {
      background-color: #fff;
      transition: all 0.35s linear;
      &:after {
        background-image: url("../img/fleche-scroll-jaune.svg");
      }
    }
  }
}

span.note_lecteur {
  font-size: 13px !important;
  line-height: normal;
}

// Marges
.mt30 {
  margin-top: 30px;
}
.mt60 {
  margin-top: 60px;
}
.mt90 {
  margin-top: 90px;
}
.mt120 {
  margin-top: 120px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb90 {
  margin-bottom: 90px;
}
.mb120 {
  margin-bottom: 120px;
}
.pt15 {
  padding-top: 15px;
}
.pt30 {
  padding-top: 30px;
}
.pt60 {
  padding-top: 60px;
}
.pt90 {
  padding-top: 90px;
}
.pt120 {
  padding-top: 120px;
}
.pb15 {
  padding-bottom: 15px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb60 {
  padding-bottom: 60px;
}
.pb90 {
  padding-bottom: 90px;
}
.pb120 {
  padding-bottom: 120px;
}

// Header
header {
  background-color: var(--blanc);
  height: 60px;
  line-height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9998;
  .logo-darkmode {
    display: none;
  }
  &.zindex {
    z-index: 99991;
  }
}
// Logo
#header-logo {
  display: inline-block;
  margin-left: 60px;
  img,
  picture {
    width: 100%;
    max-width: 160px;
  }
  video {
    margin-top: 5px;
    width: 100%;
    max-width: 160px;
  }
}

// Burger icon
#header-hamburger {
  text-align: right;
  padding-right: 60px;
  position: absolute;
  z-index: 9999;
  right: 0;
  top: 0;
  display: flex;
}
.main-telephone {
  margin-right: 30px;
  a {
    font-size: 18px;
    svg {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);

      -webkit-transition: all 900ms ease;
      -moz-transition: all 900ms ease;
      -ms-transition: all 900ms ease;
      -o-transition: all 900ms ease;
      transition: all 900ms ease;
    }
    &:hover {
      svg {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}
.hamburger {
  margin-top: 17px;
  display: flex;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;
  &:hover {
    opacity: 0.7;
  }
}
.hamburger-box {
  width: 30px;
  // height: 24px;
  display: inline-block;
  position: relative;
  // top: -12px;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 4px;
  background-color: var(--noir);
  position: absolute;
  right: 0;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
  width: 24px;
}
.hamburger-inner::after {
  bottom: -8px;
  width: 20px;
}
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  width: 30px;
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  width: 30px;
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

// Navigation
#nav-main {
  background-color: var(--blanc);
  position: fixed;
  width: 84%;
  // height: calc(100% - 60px);
  top: 60px;
  right: -100%;
  padding: 90px 90px;
  opacity: 0;
  z-index: 9998;
  transition: all 1s ease;
  height: calc(100vh - 120px);
  overflow-y: auto;
  .container-fluid {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  #menu-menu-principal {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      padding: 20px 0;
      width: 100%;
      &:first-child {
        padding-top: 0;
      }
      a {
        font-family: $gbo;
        font-size: 50px;
        line-height: 52px;
        color: var(--noir);
        letter-spacing: -1px;
        background: transparent;
        transition: all 0.35s ease;
        .main-nav-description {
          display: block;
          color: var(--grisfm);
          font-size: 30px;
          font-family: $fbp;
          letter-spacing: 0;
          transition: all 0.35s linear;
        }
        &:hover {
          background-image: url("../../assets/img/bg-nav.svg");
          background-repeat: repeat-x;
          background-position: center;
          letter-spacing: 8px;
          transition: all 0.5s ease;
          .main-nav-description {
            color: var(--noir);
            transition: all 0.5s linear;
          }
        }
      }
    }
  }
  #nav-second {
    padding: 0;
    margin: 0;
    // position: absolute;
    // bottom: 40px;
    li {
      list-style-type: none;
      display: inline-block;
      position: relative;
      &:after {
        content: "|";
        color: var(--grisfm);
        margin-right: 15px;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
      a {
        font-family: $gl;
        font-size: 15px;
        color: var(--grisfm);
        margin-right: 15px;
        transition: all 0.35s linear;
        &:hover {
          color: var(--noir);
          transition: all 0.35s linear;
        }
      }
    }
  }
  .container-equipe {
    .content-image-equipe {
      display: flex;
      flex-direction: row;
      img,
      picture {
        flex-grow: 1;
        min-width: 0;
        object-fit: cover;
      }
    }
    #menu-menu-principal {
      li {
        padding-bottom: 0;
        a {
          color: #000;
          .main-nav-description {
            color: #000;
          }
          &:hover {
            background-image: url("../../assets/img/bg-nav-blanc.svg");
          }
        }
      }
    }
    .content-nav {
      background-color: var(--jaunec);
      padding: 50px 45px;
      #menu-menu-principal {
        li {
          padding-bottom: 0;
        }
      }
    }
  }
  .container-recrutement {
    background-color: var(--grisc);
    padding: 50px 45px;
    #menu-menu-principal li {
      padding-bottom: 0;
    }
  }
  .container-contact {
    background-color: var(--noir);
    padding: 50px 45px;
    #menu-menu-principal {
      li {
        padding-bottom: 0;
        a {
          color: var(--blanc);
          .main-nav-description {
            color: var(--blanc);
          }
        }
      }
    }
  }
  &.is-active {
    opacity: 1;
    right: 0;
    transition: all 1s ease;
  }
}
#nav-overlay {
  background-color: var(--jaunec);
  opacity: 0.85;
  z-index: 110;
  left: auto;
  right: 60px;
  display: none;
  position: fixed;
}

// Page
.page {
  padding-top: 60px;
}
#page-intro {
  height: calc(100vh - 120px);
  width: calc(100vw - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  #page-intro-titre {
    position: relative;
    z-index: 10;
    display: inline-block;
    &:after {
      content: "";
      background-image: url(../../assets/img/virgule-jaune.svg);
      width: 40px;
      height: 40px;
      background-size: 40px;
      position: absolute;
      right: -50px;
      bottom: 10px;
    }
  }
  #page-intro-sous-titre {
    position: relative;
    font-family: $fbp;
    z-index: 100;
    font-size: 55px;
    line-height: 1;
    margin-bottom: 0;
  }
  #c-blanc {
    position: absolute;
    transform: rotate(180deg);
    -webkit-animation: rotation 30s linear infinite;
    z-index: 5;
  }
  #c-noir-small,
  #c-jaune-small {
    position: absolute;
    transform: rotate(-90deg);
    z-index: 5;
    -webkit-animation: rotation 2s linear infinite;
  }
  #vagues-jaune,
  #vagues-blanc,
  #vagues-noir,
  #virgule-gris {
    position: absolute;
  }
  #vagues-noir {
    object-fit: contain;
  }
}
.page-content {
  margin-bottom: 90px;
}
#scroll-icon {
  width: 30px;
  height: auto;
  position: absolute;
  bottom: 60px;
  left: calc(50% - 15px);
  animation-name: scrollIcon;
  animation-duration: 4s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}
.page-cta {
  background-color: var(--grisf);
  font-family: $gbo;
  font-size: 40px;
  color: var(--noir);
  text-align: center;
  display: block;
  padding-top: 90px;
  padding-bottom: 90px;
  width: 100%;
  margin: 0 0 80px;
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    background-color: var(--jaunec);
    color: var(--noir);
    letter-spacing: 3px;
    transition: all 0.5s ease;
  }
}
@keyframes scrollIcon {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.page-id-25,
.page-id-43,
.page-id-37 {
  #page-intro {
    #page-intro-titre {
      &:after {
        background-image: url(../../assets/img/virgule-blanc.svg);
      }
    }
  }
}

// Single
.single {
  padding-top: 60px;
  .single-single-container {
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  #single-intro {
    height: 550px;
    background-size: cover;
    background-position: center;
  }
  #single-projets-intro {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 2px solid var(--jaunec);
    height: auto;
    img {
      object-fit: cover;
      width: 100%;
      max-height: 700px;
    }
  }
  #single-projets-titre {
    margin-top: 80px;
    margin-bottom: 40px;
    h1 {
      font-size: 46px;
      line-height: 48px;
      margin: 0;
    }
    #scroll-icon {
      position: relative;
      display: none;
      bottom: auto;
      margin-top: 30px;
    }
  }
  #single-nav {
    background-color: var(--grisf);
    margin-bottom: 100px;
    ul {
      padding-left: 0;
      margin: 0 auto;
      text-align: center;
      li {
        display: inline-block;
        list-style-type: none;
        a {
          text-transform: uppercase;
          font-family: $gbo;
          font-size: 20px;
          color: var(--noir);
          padding: 25px 50px;
          display: block;
          transition: all 0.5s linear;
          &#single-nav-prev {
            img,
            picture,
            svg {
              height: 18px;
              transform: rotate(90deg);
              margin-right: 20px;
              opacity: 0;
              transition: all 0.5s linear 0.25s;
            }
          }
          &#single-nav-next {
            img,
            picture,
            svg {
              height: 18px;
              transform: rotate(-90deg);
              margin-left: 20px;
              opacity: 0;
            }
          }
        }
        &:hover {
          background-color: var(--jaunec);
          transition: all 0.5s linear;
          img,
          picture,
          svg {
            opacity: 1 !important;
            transition: all 0.5s linear 0.25s;
          }
        }
      }
    }
  }
  .container-nav-back {
    a {
      text-transform: uppercase;
      font-family: $gbo;
      font-size: 20px;
      color: var(--noir);
      display: block;
      transition: all 0.5s linear;
    }
    a#single-nav-back {
      height: 80px;
      padding: 25px !important;
      display: flex;
      align-items: center;
      .grid-back {
        height: 20px;
        display: flex;
      }
      &:hover {
        color: var(--jaunec);
        transition: all 0.5s linear;
      }
    }
  }
  .single-container {
    margin-top: 40px;
    h1 {
      font-family: $gbo;
      font-size: 60px;
      line-height: 62px;
      letter-spacing: normal;
      margin-top: 80px;
      margin-bottom: 80px;
    }
    p {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    #single-metas {
      padding-left: 0;
      li {
        list-style-type: none;
        display: inline-block;
      }
      #single-metas-date {
        font-family: $fbp;
        font-size: 30px;
        color: var(--noir);
      }
      #single-metas-category {
        // width: 220px;
        .post-categories {
          padding-left: 0;
          margin-left: 20px;
          margin-right: 0;
        }
        a {
          font-family: $gbo;
          font-size: 20px;
          color: var(--jaunef);
          text-transform: uppercase;
          letter-spacing: 1.5px;
        }
      }
    }
    #single-content {
      a {
        font-family: $gbo;
        text-decoration: underline;
      }
    }
  }
}
.single-post {
  padding-bottom: 180px;
  h1 {
    font-size: 60px;
    line-height: 70px;
    letter-spacing: normal;
    margin-left: 12%;
    margin-right: 12%;
  }
  h2 {
    font-size: 25px;
    line-height: 35px;
    letter-spacing: normal;
    margin-top: 30px;
    margin-left: 12%;
    margin-right: 12%;
  }
  h3 {
    font-size: 20px;
    line-height: 30px;
    margin-top: 20px;
    margin-left: 12%;
    margin-right: 12%;
  }
  p,
  blockquote,
  ul,
  ol,
  iframe {
    margin-left: 12%;
    margin-right: 12%;
  }
  #single-content {
    margin-top: 60px;
    margin-bottom: 90px;
  }
  iframe {
    margin: 0;
  }
}

// Single projets
#single-projets-title {
  span {
    text-transform: uppercase;
    font-size: 30px;
    display: block;
  }
}
.single-projets {
  padding-bottom: 120px;
  h2 {
    font-family: $gbo;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: normal;
    margin: 30px auto;
    display: block;
    &:after {
      content: none;
    }
  }
  h3 {
    font-family: $gbo;
    font-size: 24px;
  }
}
.galerie-image {
  margin: 15px auto;
}
.projet-avis {
  h2 {
    margin-top: 0;
  }
}
.avis_item {
  background-color: var(--blanc);
  padding: 45px 60px 45px;
  border: 2px solid var(--jaunec);
  position: relative;
  a {
    background-color: var(--jaunec);
    font-family: $gbo;
    padding: 4px 12px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
    margin-top: 20px;
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0;
    color: #000;
    &:hover {
      background-color: var(--noir);
      color: var(--jaunec);
    }
  }
}
.avis_texte {
  font-family: $fbp;
  font-size: 26px;
  font-style: italic;
}
.avis_name {
  margin-bottom: 0;
  span {
    font-family: $gbo;
  }
}
// Footer

footer {
  background-color: var(--blanc);
  min-height: 60px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  z-index: 999;
  #footer-cta {
    font-family: $gbo;
    font-size: 32px;
    line-height: 1;
    padding-left: 60px;
    margin: 40px auto;
    display: none;
    opacity: 0;
    position: relative;
    span {
      font-family: $fbp;
      font-size: 28px;
      color: var(--noir) !important;
      font-style: italic;
      display: block;
    }
    &:hover {
      color: var(--jaunef);
    }
  }
  #footer-social {
    text-align: right;
    position: absolute;
    right: 60px;
    bottom: 0;
    ul {
      padding-left: 0;
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;
      li {
        display: inline-block;
        list-style-type: none;
        a,
        a:active,
        a:visited {
          width: 20px;
          height: 20px;
          color: var(--noir);
          margin: 0 6px;
          display: block;
          transform: rotate(0deg);
          transition: color 0.5s ease, transform 0.75s linear;
          &:hover {
            color: var(--jaunef);
            transform: rotate(360deg);
            transition: color 0.5s ease, transform 0.35s linear;
          }
        }
        img {
          width: 20px;
        }
      }
    }
  }
  &.footer-expand {
    transition: all 0.35s ease;
    #footer-cta {
      display: inline-block;
      opacity: 1;
      transition: all 0.35s ease;
      &:after {
        content: "";
        background-image: url(../../assets/img/fleche-scroll-jaune.svg);
        width: 20px;
        height: auto;
        display: block;
        position: absolute;
        right: -30px;
        bottom: -2px;
        transform: rotate(-90deg);
      }
    }
  }

  // Toggle DarkMode
  .message_darkmode {
    min-height: 0px;
    min-width: 0px;
    position: absolute;
    width: 285px;
    bottom: 40px;
    height: 50px;
    display: none;
    visibility: visible !important;
    .close-message {
      transform: translate(-50%, -50%);
      position: absolute;
      right: 0;
      .icon-close-message {
        cursor: pointer;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        width: 20px;
        height: 20px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        svg {
          left: auto;
          right: 3px;
          width: 15px;
        }
      }
    }
    .pop-message-darkmode {
      background-color: white;
      padding: 10px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      position: absolute;
      border-radius: 10px;
      text-align: center;
      p {
        color: black;
        font-weight: bold;
        font-size: 12px;
        margin: 0;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 12%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: white;
        border-bottom: 0;
        margin-left: -10px;
        margin-bottom: -10px;
        filter: none;
      }
    }
    &.close {
      display: none;
    }
    &.active {
      display: block;
      &.close {
        display: none;
      }
    }
  }
  #theme_switch {
    position: relative;
    padding-left: 60px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  label {
    width: 47px;
    height: 24px;
    background-color: #1c1c1c;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    // transform: scale(1.5);
  }

  .ball {
    width: 20px;
    height: 20px;
    background-color: #edecec;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  /*  target the elemenent after the label*/
  input[type="checkbox"]:checked + .ball {
  }

  .fa-moon,
  .fa-sun {
    width: 15px;
    height: 15px;
    path {
      fill: var(--jaunef);
    }
  }
}

html[data-theme="dark"] {
  .ball {
    transform: translateX(22px);
  }
}

html[data-theme="light"] {
  .ball {
    transform: translateX(0);
  }
}

// Accueil
.home {
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  main {
    padding-bottom: 180px;
  }
  #virgule {
    height: 80%;
    width: auto;
    opacity: 0.33;
    position: absolute;
    top: 12%;
    right: 10%;
  }
  .overlay {
    width: calc(100vw - 60px);
    left: 0;
    z-index: -2;
  }
  h3 {
    margin-bottom: 30px;
  }
  .actu {
    .wp-post-image {
      margin-bottom: 20px;
    }
    .actu-titre {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 0;
    }
  }
}
.projets-colores {
  position: relative;
  margin-top: 90px;
  margin-bottom: 120px;
  .row {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
  #projets-deco-1 {
    position: absolute;
    right: 35%;
    top: -60px;
    font-family: $fbp;
    font-style: italic;
    font-size: 22px;
    &:before {
      content: "";
      background-image: url("../../assets/img/fleche-gauche.svg");
      background-size: contain;
      width: 18px;
      height: 18px;
      display: inline-block;
      position: relative;
      top: 18px;
      margin-right: 5px;
    }
  }
  #projets-deco-2 {
    position: absolute;
    left: 35%;
    top: -60px;
    font-family: $fbp;
    font-style: italic;
    font-size: 22px;
    &:after {
      content: "";
      background-image: url("../../assets/img/fleche-gauche.svg");
      background-size: contain;
      width: 18px;
      height: 18px;
      display: inline-block;
      position: relative;
      top: 18px;
      margin-left: 5px;
      transform: rotate(-90deg);
    }
  }
}
.projet-colore {
  a {
    display: flex;
    align-items: center;
    padding: 60px 30px;
    text-align: center;
    color: #fff;
    font-family: $gbo;
    font-size: 28px;
    line-height: 26px;
    height: 100%;
    &:hover {
      background-color: var(--jaunec) !important;
      color: #000;
    }
  }
}
.prestation-item {
  padding-left: 0;
  margin-bottom: 15px;
  font-size: 17px;
  a:hover {
    transition: all 0.25s linear;
    color: #000;
  }
  span {
    font-family: $gbo;
    background-color: var(--blanc);
    display: block;
    padding: 20px 30px;
    height: 100%;
    &:hover {
      transition: all 0.25s linear;
      background-color: var(--jaunec);
      color: #000;
    }
  }
}
#home-clients {
  margin-bottom: 120px;
  ul {
    padding-left: 0;
    margin: 0;
    li {
      width: 33.33%;
      padding: 60px;
      list-style-type: none;
      float: left;
      border: 1px solid var(--grisf);
      text-align: center;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      img,
      picture,
      svg {
        max-width: 170px;
        max-height: 50px;
      }
      .clients-svg {
        width: 170px;
        height: 50px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
#currentTime {
  font-family: $fbp;
  font-size: 30px;
  margin-bottom: 30px;
  margin-left: 8px;
  display: block;
  color: var(--jaunec);
}
#home-caption {
  padding: 0 5%;
  position: relative;
  z-index: 10;
  margin-top: -60px;
  p {
    font-family: $gbl;
    font-size: 90px;
    line-height: 90px;
    color: #fff;
    &:after {
      content: "";
      background-image: url(../../assets/img/virgule-jaune.svg);
      width: 40px;
      height: 40px;
      background-size: 40px;
      position: absolute;
      right: 70px;
      bottom: 58px;
    }
  }
  h1 {
    font-family: $fbp;
    font-size: 38px;
    line-height: 40px;
    color: #fff;
    margin-bottom: 0;
    letter-spacing: 0;
  }
}
// Coeur de métier
.page-template-template-metiers {
  #page-intro {
    background-color: var(--jaunec);
    #page-intro-titre {
      left: 9%;
    }
    #page-intro-sous-titre {
      left: -5%;
    }
    #c-blanc {
      width: 40%;
      height: auto;
      top: 12%;
      left: 8%;
    }
    #c-noir-small {
      width: 44px;
      height: auto;
      top: 12%;
      right: 10%;
    }
    #vagues-jaune {
      width: 40%;
      top: 29%;
      left: 15%;
    }
    #vagues-noir {
      bottom: 15%;
      right: 10%;
      width: 10%;
    }
  }
}
#coeurdemetier-conseil {
  position: relative;
  &:before {
    content: url("../img/virgule-blanc.svg");
    width: 50%;
    height: auto;
    display: block;
    position: absolute;
    top: 10%;
    left: 25%;
  }
}
#coeurdemetier-conception {
  position: relative;
  &:before {
    content: "";
    background-size: contain;
    background-image: url("../img/virgule-blanc.svg");
    width: 50%;
    height: auto;
    position: absolute;
    top: 10%;
    right: 25%;
  }
}
#coeurdemetier-creation {
  position: relative;
  &:before {
    content: "";
    background-size: contain;
    background-image: url("../img/virgule-blanc.svg");
    width: 50%;
    height: auto;
    position: absolute;
    top: 10%;
    left: 25%;
  }
}
// Adn
.page-template-template-adn,
.page-template-template-candidature,
.page-template-template-candidature-graphiste,
.page-template-template-candidature-web,
.page-template-template-caroule {
  #page-intro {
    background-color: var(--noir);
    #page-intro-titre {
      color: #fff;
      right: -7%;
    }
    #page-intro-sous-titre {
      color: #fff;
      left: -15%;
    }
    #c-blanc {
      width: 40%;
      height: auto;
      left: 60%;
      opacity: 0.07;
    }
    #c-jaune-small {
      width: 44px;
      height: auto;
      top: 12%;
      left: 10%;
    }
    #vagues-blanc {
      width: 40%;
      top: 42%;
      left: 20%;
      opacity: 0.2;
    }
    #vagues-jaune {
      width: 40%;
      bottom: -180px;
      left: 0;
      transform: rotate(90deg);
    }
    #double-vagues-blanc {
      width: 2%;
      height: auto;
      position: absolute;
      bottom: 10%;
      right: 15%;
      display: inline-block;
      z-index: 6;
      opacity: 1;
    }
  }
}
#adn-heritage {
  position: relative;
  &:before {
    content: "";
    background-size: contain;
    background-image: url("../img/vagues-2-blanc.svg");
    width: 250%;
    height: auto;
    position: absolute;
    top: 20%;
    left: -130%;
  }
}
// À l'affiche
.page-template-template-affiche,
.page-template-template-projets,
.post-type-archive-projets {
  #page-intro {
    background-color: var(--grisc);
    #page-intro-titre {
      color: var(--noir);
      left: 120px;
    }
    #page-intro-sous-titre {
      position: relative;
      color: var(--noir);
      right: 90px;
    }
    #c-blanc {
      width: 35%;
      height: auto;
      top: 0%;
      left: 0%;
      transform: rotate(0) !important;
      display: inline-block;
      opacity: 1;
    }
    #vagues-jaune {
      width: 50%;
      top: 35%;
      left: 32%;
      opacity: 1;
    }
    #vagues-noir {
      bottom: 15%;
      right: 10%;
      width: 10%;
    }
    #c-noir-small {
      width: 60px;
      height: auto;
      top: 18%;
      left: 20%;
    }
    #virgule-gris {
      top: 15%;
      right: 10%;
      width: 110px;
    }
  }
}
.projet {
  transition: all 0.5s linear;
  .projet-infos {
    padding: 150px 90px 150px;
    text-align: center;
    .projet-date {
      font-family: $fbp;
      font-size: 30px;
      color: #fff;
      position: relative;
      z-index: 2;
    }
    .projet-titre {
      font-family: $gbo;
      font-size: 38px;
      line-height: 38px;
      color: #fff;
      letter-spacing: 1px;
      z-index: 2;
    }
    .projet-desc {
      margin-top: 100px;
      p {
        color: #fff;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .projet-competences {
      position: relative;
      padding: 0;
      z-index: 2;
      margin-top: 15px;
      li {
        font-family: $gl;
        font-size: 16px;
        list-style-type: none;
        display: inline-block;
        color: #fff;
        &:after {
          content: "|";
          margin: 0 10px;
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }
  }
  &:last-child {
    margin-bottom: 80px;
  }
  .projet-image {
    background-size: cover;
    background-position: center;
    transition: all 0.5s linear;
  }
  &:hover {
    transition: all 0.5s linear;
    .projet-image {
      position: relative;
      filter: blur(1px);
      &:before {
        content: "";
        background-size: contain;
        background-image: url(../../assets/img/fleche-scroll-blanc.svg);
        width: 160px;
        height: auto;
        display: block;
        transform: rotate(-90deg);
        position: absolute;
        top: calc(50% - 80px);
        right: calc(50% - 50px);
      }
    }
  }
}

// Projets
.page-template-template-projets {
  .main-footer-expand {
    padding-bottom: 0;
  }
}

// Actualités
.wp-caption {
  max-width: 100%;
}
.wp-caption-text {
  // background-color: #fff;
  font-size: 12px;
  padding: 5px 15px;
  text-align: center;
  a {
    font-family: Gotham Regular;
  }
}
.page-template-template-actualites {
  #page-intro {
    background-color: var(--jaunec);
    #page-intro-titre {
      color: var(--noir);
      left: 60px;
    }
    #page-intro-sous-titre {
      position: relative;
      color: var(--noir);
      right: 90px;
      width: 80%;
      text-align: right;
    }
    #c-blanc {
      width: 40%;
      height: auto;
      top: -30%;
      right: 5%;
      display: inline-block;
    }
    #vagues-jaune {
      width: 54%;
      top: 46%;
      left: 5%;
    }
    #c-noir-small {
      width: 40px;
      height: auto;
      top: 10%;
      right: 10%;
    }
    #double-vagues-blanc {
      width: 2%;
      height: auto;
      position: absolute;
      top: 10%;
      left: 30%;
      transform: rotate(90deg);
      display: inline-block;
      z-index: 6;
      opacity: 0.5;
    }
  }
  .page-cta {
    margin: 0 0 20px;
  }
  .actu-multiple:nth-child(1) {
    display: none;
  }
}

.archive {
  padding-top: 60px;
  #page-intro {
    background-color: var(--noir);
    #page-intro-titre {
      color: white;
      left: 60px;
    }
    #page-intro-sous-titre {
      position: relative;
      color: white;
      right: 90px;
      width: 100%;
      text-align: right;
    }
    #c-blanc {
      width: 40%;
      height: auto;
      top: -30%;
      right: 5%;
      opacity: 0.07;
      display: inline-block;
    }
    #vagues-jaune {
      width: 45%;
      bottom: 16%;
      left: 5%;
    }
    #c-noir-small {
      width: 40px;
      height: auto;
      top: 10%;
      right: 10%;
    }
    #double-vagues-blanc {
      width: 2%;
      height: auto;
      position: absolute;
      top: 10%;
      left: 30%;
      transform: rotate(90deg);
      display: inline-block;
      z-index: 6;
      opacity: 0.5;
    }
  }
  .actu-image {
    height: 380px;
    max-height: 100%;
    object-fit: cover;
  }
}

.actu {
  margin-bottom: 60px;
  .actu-titre {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -2px;
    margin-bottom: 15px;
    color: var(--noir);
    &:hover {
      color: var(--noir);
    }
  }
  .actu-image {
    margin-bottom: 30px;
  }
  .actu-extrait p {
    margin-bottom: 0;
  }
  .actu-citation {
    background-color: var(--jaunec);
    padding: 30px;
    font-family: $fbp;
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    color: #fff;
    margin-bottom: 30px;
    position: relative;
    height: 480px;
    &:after {
      position: absolute;
      bottom: 30px;
      right: 30px;
      content: "”";
      font-size: 60px;
      line-height: 0;
      color: var(--noir);
    }
  }
  .actu-icone {
    background-color: var(--noir);
    margin-bottom: 30px;
    height: 480px;
  }

  .post-categories {
    padding-left: 10px;
    display: inline-block;
    li {
      list-style-type: none;
      a {
        font-family: $gbo;
        text-transform: uppercase;
        color: var(--jaunef);
      }
    }
  }
  .actu-date {
    margin-top: 10px;
    font-family: $fbp;
    font-size: 20px;
    display: inline-block;
  }
}
#actu-principale {
  margin-top: 60px;
  #actu-principale-texte {
    align-self: center;
    // display: flex;
    flex-direction: column;
  }
  .actu-titre {
    font-size: 50px;
    line-height: 53px;
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
    max-height: 540px;
    object-fit: cover;
  }
}
#actualites .container {
  &:hover .actu {
    opacity: 0.5;
    transition: all 1s ease;
  }
  .actu:hover {
    opacity: 1;
    transition: all 1s ease;
  }
}
#actualites-filtres {
  background-color: var(--grisf);
  ul {
    padding-left: 0;
    margin: 0 auto;
    text-align: center;
    li {
      display: inline-block;
      list-style-type: none;
      background-color: var(--grisf);
      padding: 12px 12px;
      text-transform: uppercase;
      font-family: $gbo;
      font-size: 16px;
      &:hover {
        background-color: var(--jaunec);
      }
    }
  }
  .searchandfilter {
    ul {
      li.sf-field-category {
        padding: 0;
        &:hover {
          background-color: transparent;
        }
        .sf-input-checkbox,
        .sf-input-radio {
          display: none;
        }
        ul {
          li.sf-level-0:nth-child(1) {
            display: none;
          }
        }
      }
    }
    li {
      label {
        cursor: pointer;
      }
    }
  }
}

// Équipe
.page-template-template-equipe {
  #page-intro {
    display: block;
    background-color: #fff000;
    width: calc(100vw - 120px);
    height: auto;
    position: relative;
    #page-intro-titre {
      position: absolute;
      top: 10%;
      right: 30%;
    }
    #page-intro-sous-titre {
      position: absolute;
      top: 20%;
      left: 35%;
      text-align: left;
      width: 80%;
      font-size: 40px;
    }
    #c-blanc {
      width: 40%;
      height: auto;
      top: 12%;
      left: 30%;
      display: none;
    }
    #vagues-jaune {
      width: 40%;
      top: 6%;
      left: 5%;
      z-index: 2;
    }
    #vagues-noir {
      top: 25%;
      left: 10%;
      width: 10%;
      transform: rotate(90deg);
    }
    .pin {
      position: absolute;
      cursor: pointer;
    }
    #david {
      bottom: 40%;
      left: 11%;
      animation-name: rotation;
      animation-duration: 12s;
      animation-delay: -2s;
      animation-iteration-count: infinite;
    }
    #maxime {
      bottom: 40%;
      left: 50%;
      animation-name: rotation;
      animation-duration: 10s;
      animation-delay: -4s;
      animation-iteration-count: infinite;
    }
    #claire {
      top: 40%;
      left: 57%;
      animation-name: rotation;
      animation-duration: 11s;
      animation-delay: -1s;
      animation-iteration-count: infinite;
    }
    #helene {
      top: 47%;
      right: 24%;
      animation-name: rotation;
      animation-duration: 12s;
      animation-delay: -2s;
      animation-iteration-count: infinite;
    }
    #aly {
      bottom: 20%;
      right: 34%;
      animation-name: rotation;
      animation-duration: 14s;
      animation-delay: -4s;
      animation-iteration-count: infinite;
    }
    #marion {
      bottom: 25%;
      left: 37%;
      animation-name: rotation;
      animation-duration: 14s;
      animation-delay: -4s;
      animation-iteration-count: infinite;
    }
    #melissa {
      top: 40%;
      left: 25%;
      animation-name: rotation;
      animation-duration: 14s;
      animation-delay: -4s;
      animation-iteration-count: infinite;
    }
    #caroline {
      bottom: 44%;
      right: 10%;
      animation-name: rotation;
      animation-duration: 14s;
      animation-delay: -4s;
      animation-iteration-count: infinite;
    }
  }
  #photo-equipe {
    position: relative;
    display: block;
    width: calc(100vw - 120px);
    height: auto;
  }
  #presentation-equipe {
    width: calc(100vw - 120px);
    // margin-bottom: 60px;
    .photo-salarie,
    .infos-salarie {
      width: 50%;
      height: 650px;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      float: left;
    }
    .infos-salarie {
      background-color: black;
      padding: 100px;
      float: left;
    }
    h3 {
      color: var(--jaunef);
      font-family: $gbl;
      font-size: 70px;
      margin-bottom: 0px;
    }
    .fonction-salarie {
      font-family: $fbp;
      color: #fff;
      font-size: 25px;
    }
    p {
      color: #fff;
    }
    .petit-plus {
      color: var(--jaunec);
      font-family: $gbl;
      font-size: 30px;
      margin-top: 15px;
      margin-bottom: 0px;
    }
    .vagues-4-jaune {
      margin: 20px 0px;
    }
    .vagues-4-blanc {
      width: 230px;
      transform: rotate(90deg);
      position: absolute;
      right: -40px;
      top: 150px;
    }
    .carousel-control-prev,
    .carousel-control-next {
      width: 6%;
    }
    .carousel-control-next-icon {
      background-image: url("../img/fleche-scroll-jaune.svg");
      transform: rotate(-90deg);
    }
    .carousel-control-prev-icon {
      background-image: url("../img/fleche-scroll-jaune.svg");
      transform: rotate(90deg);
    }
    .david {
      background-image: url("../img/equipe/portrait-david.png");
    }
    .claire {
      background-image: url("../img/equipe/portrait-claire.png");
    }
    .maxime {
      background-image: url("../img/equipe/portrait-maxime.png");
    }
    .helene {
      background-image: url("../img/equipe/portrait-helene.png");
    }
    .romain {
      background-image: url("../img/equipe/portrait-romain.png");
    }
  }
  .glide__arrow {
    display: block;
    width: 30px;
    height: auto;
    border: 0;
    text-shadow: none;
    box-shadow: none;
    padding: 3px;
  }
  .glide__arrow--right {
    img,
    picture {
      transform: rotate(-90deg);
    }
  }
  .glide__arrow--left {
    img,
    picture {
      transform: rotate(90deg);
    }
  }
}
#construction {
  position: absolute;
  width: 60%;
  top: 16%;
  left: 3%;
  z-index: 1;
}
.membre {
  padding-top: 50px;
  padding-bottom: 50px;
  &:first-child {
    margin-top: 50px;
  }
  &:last-child {
    margin-bottom: 100px;
  }
  .vagues-4-jaune {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}
.membre-texte {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.membre-prenom {
  color: var(--jaunec);
}
.membre-metiers {
  font-family: $fbp;
  font-size: 45px;
  line-height: 1;
  color: var(--noir);
  margin-bottom: 30px;
}

// Contact
.page-template-template-contact {
  #page-intro {
    background-color: var(--noir);
    #page-intro-titre {
      color: #fff;
      left: 120px;
    }
    #page-intro-sous-titre {
      position: relative;
      color: #fff;
      right: 90px;
    }
    #c-blanc {
      width: 40%;
      height: auto;
      bottom: -2%;
      left: 2%;
      transform: rotate(-90deg) !important;
      display: inline-block;
      opacity: 0.07;
    }
    #vagues-blanc {
      width: 54%;
      top: 40%;
      left: 42%;
      opacity: 0.07;
    }
    #c-jaune-small {
      width: 60px;
      height: auto;
      top: 10%;
      right: 10%;
    }
    #double-vagues-jaune {
      width: 2%;
      height: auto;
      position: absolute;
      top: 16%;
      left: 12%;
      z-index: 6;
    }
  }
  #form_formulairedecontact {
    input[type="text"],
    input[type="tel"],
    input[type="email"] {
      background-color: var(--grisf);
      height: 55px;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      font-size: 15px;
      font-family: $gl;
    }
    textarea {
      background-color: var(--grisf);
      min-height: 285px;
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }
    #frm_field_12_container {
      margin-bottom: 0;
    }
    .frm_primary_label {
      font-family: $gbo;
      font-size: 16px;
      color: var(--noir);
    }
    .frm_checkbox {
      float: left;
      width: 20%;
      text-align: center;
      color: var(--noir);
      border: 1px solid var(--grisc);
      label {
        padding: 20px 10px;
        text-transform: uppercase;
        font-family: $gl;
        font-size: 14px;
        background-color: var(--grisf);
        margin-bottom: 0;
        cursor: pointer;
        transition: all 0.35s linear;
      }
      input {
        opacity: 0;
      }
    }
    .checked {
      background-color: var(--jaunec) !important;
      transition: all 0.35s linear;
    }
    .frm_submit {
      text-align: right;
      button {
        border: 2px solid var(--jaunec);
        background-color: var(--jaunec);
        border-radius: 0;
        font-family: $gbo;
        font-size: 15px;
        text-transform: uppercase;
        color: #000;
        padding: 20px;
        letter-spacing: 1.5px;
        transition: all 0.35s linear;
        position: relative;
        width: 100%;
        &:after {
          content: "";
          background-size: contain;
          background-image: url("../img/fleche-scroll-blanc.svg");
          transform: rotate(-90deg);
          width: 18px;
          display: inline-block;
          margin-left: 40px;
        }
        &:hover {
          background-color: #fff;
          transition: all 0.35s linear;
          &:after {
            content: "";
            background-size: contain;
            background-image: url("../img/fleche-scroll-jaune.svg");
          }
        }
      }
    }
  }
}
#map {
  height: 100%;
  min-height: 500px;
}
#contact-postuler {
  border: 2px solid var(--jaunec);
  border-radius: 0;
  font-family: $gbo;
  font-size: 15px;
  text-transform: uppercase;
  color: var(--noir);
  padding: 20px;
  letter-spacing: 1.5px;
  margin-top: 20px;
  display: inline-block;
  transition: all 0.35s linear;
  position: relative;
  &:after {
    background-image: url("../img/fleche-scroll-jaune.svg");
    content: "";
    background-size: contain;
    transform: rotate(-90deg);
    width: 18px;
    display: inline-block;
    margin-left: 40px;
  }
  &:hover {
    background-color: #fff;
    transition: all 0.35s linear;
  }
}
#contact-questions {
  position: relative;
  &:before {
    content: "";
    background-size: contain;
    background-image: url("../img/fleche-scroll-blanc.svg");
    width: 70%;
    height: auto;
    display: block;
    position: absolute;
    top: 20%;
    left: 10%;
    transform: rotate(-90deg);
  }
  h2 {
    right: 12%;
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      display: inline-block;
      list-style-type: none;
      a,
      a:active,
      a:visited {
        color: var(--noir);
        margin: 0 6px;
        display: block;
        transform: rotate(0deg);
        transition: color 0.5s ease, transform 0.75s linear;
        &:hover {
          color: var(--jaunef);
          transform: rotate(360deg);
          transition: color 0.5s ease, transform 0.35s linear;
        }
      }
    }
  }
}
#contact-projets {
  position: relative;
  &:before {
    content: "";
    background-size: contain;
    background-image: url("../img/fleche-scroll-blanc.svg");
    background-position-y: 20%;
    background-position-x: 0%;
    width: 70%;
    height: auto;
    display: block;
    position: absolute;
  }
}
#contact-candidature {
  position: relative;
  margin-bottom: 100px;
  &:before {
    content: "";
    background-size: contain;
    background-image: url("../img/fleche-scroll-blanc.svg");
    width: 70%;
    height: auto;
    position: absolute;
    top: 20%;
    left: 0;
    transform: rotate(-180deg);
  }
  h2 {
    right: 12%;
  }
}

// Mentions légales
.page-id-399 {
  #page-intro {
    background-color: var(--jaunec);
    #page-intro-titre {
      right: 0;
      text-align: right;
    }
    #page-intro-sous-titre {
      left: 30%;
      text-align: left;
      width: 80%;
    }
    #c-blanc {
      width: 40%;
      height: auto;
      top: 4%;
      left: 35%;
    }
    #c-noir-small {
      width: 44px;
      height: auto;
      bottom: 12%;
      right: 20%;
    }
    #vagues-jaune {
      width: 40%;
      top: 50%;
      left: 25%;
      height: 150px;
    }
    #vagues-noir {
      top: 25%;
      left: 10%;
      width: 10%;
      transform: rotate(90deg);
    }
  }
  .page-content {
    padding-top: 120px;
    padding-bottom: 90px;
    h2 {
      font-size: 70px;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    h3 {
      font-size: 38px;
    }
    ul {
      li {
        font-family: $gl;
      }
    }
  }
}
#nav-fin-projets {
  .wp-post-image,
  .nav-fin-projet {
    display: block;
    .nav-fin-projet-titre {
      font-size: 20px;
      letter-spacing: 1px;
      transition: all 0.35s linear;
      margin-left: 0;
      margin-top: 30px;
      font-family: $gbo;
    }
  }
}
#nav-fin-projets {
  &:hover .nav-fin-projet {
    opacity: 0.5;
    transition: all 0.5s ease;
  }
  .nav-fin-projet:hover {
    opacity: 1;
    transition: all 0.5s ease;
  }
}
#retour-projets {
  display: block;
  font-family: $gl;
  padding: 15px 25px;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  margin: 45px auto 0;
  transition: all 0.35s linear;
  position: relative;
  letter-spacing: 2px;
  transition: all 0.35s linear;
  color: var(--noir);
  &:after {
    background-image: url("../../assets/img/virgule-jaune.svg");
    content: "";
    background-size: contain;
    width: 40px;
    height: 40px;
    position: absolute;
    right: calc(50% - 20px);
    bottom: calc(50% - 20px);
    z-index: -3;
    transform: rotate(0);
    transition: all 0.35s linear;
  }
  &:hover {
    color: var(--noir);
    letter-spacing: 6px;
    transition: all 0.35s linear;
    &:after {
      transform: rotate(45deg);
      transition: all 0.35s linear;
    }
  }
}
#lire-plus-titre {
  display: inline-block;
  font-family: $fbp;
  font-size: 42px;
  margin-bottom: 50px;
  // margin-top: 90px;
  padding: 5px 10px;
  position: relative;
  letter-spacing: inherit;
  color: var(--noir);
  &:after {
    content: "";
    background-size: contain;
    background-color: url("../../assets/img/bg-brush-jaune.svg");
    position: absolute;
    top: 3px;
    left: -10px;
    width: calc(100% + 20px);
    z-index: -2;
    padding: 5px;
    transform: rotate(-2deg);
  }
}
.btn-brush {
  position: relative;
  font-family: $fbp;
  font-size: 22px;
  color: #000;
  margin-top: 30px;
  transition: all 0.35s linear;
  &:after {
    content: "";
    background-size: contain;
    background-image: url("../../assets/img/bg-brush-jaune.svg");
    position: absolute;
    top: 0;
    left: -10px;
    width: calc(100% + 20px);
    z-index: -2;
  }
  &:hover {
    letter-spacing: 2px;
    color: #000;
    transition: all 0.35s linear;
  }
}
#joinup-widget {
  margin-left: auto !important;
  margin-right: auto !important;
  bottom: 40px !important;
}
.legende {
  font-family: $gl;
  font-style: italic;
  font-size: 15px;
  color: #777;
}

// Page 404
.error404 {
  #section404 {
    background-color: #000;
    height: 100vh;
    width: calc(100vw - 120px);
    display: flex;
    align-items: center;
    text-align: center;
  }
  #virgule404 {
    width: 100px;
    transform: rotateZ(225deg);
  }
  h1 {
    color: var(--jaunec);
    margin-top: 60px;
  }
  p {
    color: #fff;
    font-family: $fbp;
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 60px;
    &:after {
      content: none;
    }
  }
}

// Services
.page-template-template-services {
  #page-intro {
    background-color: var(--jaunec);
    #page-intro-titre {
      left: 9%;
      &:after {
        background-image: url(../../assets/img/virgule-blanc.svg);
      }
    }
    #page-intro-sous-titre {
      left: -5%;
    }
    #c-blanc {
      width: 40%;
      height: auto;
      top: 12%;
      left: 8%;
    }
    #c-noir-small {
      width: 44px;
      height: auto;
      top: 12%;
      right: 10%;
    }
    #vagues-jaune {
      width: 40%;
      top: 29%;
      left: 15%;
      height: 150px;
    }
    #vagues-noir {
      bottom: 15%;
      right: 10%;
      width: 10%;
      height: 150px;
    }
  }
  .hover-thumbnail {
    height: 66px;
    display: none;
    position: relative;
    img,
    picture {
      height: inherit;
      object-fit: cover;
      max-width: 180px;
    }
  }
  .hover-template-services:hover {
    display: flex;
    .hover-thumbnail {
      display: block;
      // border-top: 1px solid var(--jaunec);
      // border-bottom: 1px solid var(--jaunec);
      // border-left: 1px solid var(--jaunec);
    }
    span {
      width: 100%;
      border-left: 0;
    }
  }
}

// Newsletter
#newsletter {
  padding-bottom: 60px;
}
#newsletter-cta {
  background-color: #f2f2f2;
  padding-top: 60px;
  padding-bottom: 140px;
  iframe {
    background-color: transparent !important;
    form.mj-form {
      background-color: red !important;
    }
  }
  form.mj-form .mj-embed-shadow .mj-embedded-content {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

// Neige
.snow-canvas {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 999999;
}

// Services
.services-template-default.single-services {
  #page-intro {
    #c-blanc {
      width: 40%;
      height: auto;
      top: -30%;
      right: 5%;
      display: inline-block;
    }
    #c-noir-small {
      width: 40px;
      height: auto;
      top: 10%;
      right: 10%;
    }
    #vagues-jaune {
      width: 54%;
      top: 46%;
      left: 5%;
      height: 150px;
    }
    #double-vagues-blanc {
      width: 2%;
      height: auto;
      position: absolute;
      top: 10%;
      left: 30%;
      transform: rotate(90deg);
      display: inline-block;
      z-index: 6;
      opacity: 0.5;
    }
    &.bg-yellow {
      background-color: var(--jaunec);
      #page-intro-titre {
        &::after {
          content: url(../../assets/img/virgule-blanc.svg);
        }
      }
      #c-blanc {
        .cls-1 {
          fill: white;
        }
      }
      #c-noir-small {
        .cls-1 {
          fill: var(--noir);
        }
      }
      #vagues-jaune {
        .st0 {
          fill: var(--jaunef);
        }
      }
      #double-vagues-blanc {
        .st0 {
          fill: var(--noir);
        }
      }
      #scroll-icon {
        .st0 {
          fill: white;
        }
      }
    }
    &.bg-black {
      background-color: var(--noir);
      #page-intro-titre,
      p {
        color: white;
      }
      #c-noir-small {
        .cls-1 {
          fill: white;
        }
      }
      #vagues-jaune {
        .st0 {
          fill: var(--jaunec);
        }
      }
      #scroll-icon {
        .st0 {
          fill: var(--jaunec);
        }
      }
    }
  }
  .virgule-bg {
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  .single-container {
    h2 {
      display: flex;
      flex-direction: column;
      &::after {
        content: "";
        background-image: url(../../assets/img/vagues-4-jaune.svg);
        background-size: contain;
        width: 175px;
        height: 30px;
        margin-top: 30px;
        display: inline-block;
        position: relative;
        background-repeat: no-repeat;
        margin-top: 30px;
      }
    }
    h3 {
      font-size: 24px;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
  .section-lien-article {
    .legende-lien-article {
      position: absolute;
      right: 5%;
      bottom: 30px;
      font-family: $fbp;
      font-style: italic;
      font-size: 22px;
      &::before {
        content: "";
        background-image: url("../../assets/img/fleche-gauche.svg");
        background-size: contain;
        width: 18px;
        height: 18px;
        display: inline-block;
        position: relative;
        top: 18px;
        margin-right: 5px;
      }
    }
    img,
    picture {
      max-height: 500px;
      width: 100%;
      object-fit: cover;
    }
    .plus-deux-colonnes {
      img,
      picture {
        max-height: 300px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  // Impression numérique
  &.postid-906 {
    #page-intro {
      #vagues-jaune {
        top: 65%;
      }
      #page-intro-titre {
        white-space: nowrap;
      }
      #page-intro-sous-titre {
        text-align: right;
      }
    }
  }

  // Création de sites internet
  &.postid-996 {
    #page-intro {
      #vagues-jaune {
        top: 65%;
      }
    }
  }

  // Conseil et stratégie en marketing et communication
  // Drone : captation de plans aériens (pilote DGAC)
  &.postid-908,
  &.postid-917 {
    #page-intro {
      #c-blanc {
        left: -10%;
        top: 10%;
      }
      #vagues-jaune {
        display: none;
      }
      #page-intro-titre {
        text-align: right;
      }
      #double-vagues-blanc {
        opacity: 1;
        transform: rotate(0);
        top: auto;
        bottom: -15%;
      }
    }
  }
  // Identité & plateforme de marque
  // Motion design : écriture, illustration et animation
  &.postid-893,
  &.postid-920 {
    #page-intro {
      #vagues-jaune {
        top: 65%;
        width: 40%;
        height: 150px;
      }
      #double-vagues-blanc {
        top: -5%;
        left: 5%;
      }
      #c-blanc {
        top: 5%;
      }
      #page-intro-titre {
        text-align: left;
        &::after {
          display: none;
        }
      }
      #page-intro-sous-titre {
        text-align: right;
      }
    }
  }
  // Création graphique print
  &.postid-900 {
    #page-intro {
      #vagues-jaune {
        top: 65%;
        width: 40%;
        height: 150px;
      }
      #double-vagues-blanc {
        left: auto;
        right: 30%;
      }
      #c-blanc {
        right: auto;
        left: 5%;
      }
      #page-intro-sous-titre {
        text-align: right;
      }
      #page-intro-titre {
        left: 0%;
        text-align: left;
      }
    }
  }
  // Photographies d’entreprise et de produits
  &.postid-919 {
    #page-intro {
      #vagues-jaune {
        top: 65%;
        width: 40%;
        height: 150px;
      }
      #double-vagues-blanc {
        transform: rotate(0deg);
        left: 15%;
        top: -5%;
      }
      #page-intro-sous-titre {
        text-align: right;
      }
      #page-intro-titre {
        left: 0%;
        text-align: left;
      }
    }
  }
  // Vidéos d’entreprise : réalisation, production et montage
  &.postid-913 {
    #page-intro {
      #vagues-jaune {
        top: 65%;
        left: 5%;
        height: 150px;
      }
      #double-vagues-blanc {
        left: auto;
        right: 30%;
        top: 0%;
      }
      #c-blanc {
        right: auto;
        left: 2%;
      }
      #page-intro-sous-titre {
        text-align: left;
      }
      #page-intro-titre {
        left: 0%;
        text-align: right;
      }
    }
  }
}

// ÇaRoule
.page-template-template-avis {
  #page-caroule-mag {
    border-bottom: 2px solid var(--jaunec);
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  #caroule-mag-titre {
    margin-top: 80px;
    h1 {
      font-size: 46px;
      line-height: 48px;
      margin: 0;
      span {
        position: relative;
        &:after {
          background-image: url(../../assets/img/virgule-jaune.svg);
          width: 40px;
          height: 40px;
          content: "";
          background-size: contain;
          position: absolute;
          right: -35px;
          bottom: -35px;
        }
      }
    }
    p {
      margin-top: 50px;
    }
  }
  .section-row {
    padding-top: 60px;
  }
  .feedbackbox {
    .frm_style_style-formidable {
      .frm_form_field {
        .frm-star-group {
          .star-rating {
            width: 56px;
            height: 36px;
            &::before {
              content: url("../img/virgule-feedback.svg");
            }
          }
          input + label {
            width: 56px;
            height: 36px;
            &::before {
              content: url("../img/virgule-feedback.svg");
            }
          }
          .star-rating-on {
            &::before {
              content: url("../img/virgule-jaune-feedback.svg");
            }
          }
        }
      }
      input[type="text"],
      input[type="tel"],
      input[type="email"] {
        background-color: var(--grisf);
        height: 55px;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        font-size: 15px;
        font-family: $gl;
      }
      textarea {
        background-color: var(--grisf);
        min-height: 285px;
        border: 0;
        border-radius: 0;
        box-shadow: none;
      }
      .frm_primary_label {
        font-family: $gbo;
        font-size: 16px;
        color: var(--noir);
        margin-bottom: 12px;
      }
      .frm_submit {
        text-align: right;
        button {
          border: 2px solid var(--jaunec);
          background-color: var(--jaunec);
          border-radius: 0;
          font-family: $gbo;
          font-size: 15px;
          text-transform: uppercase;
          color: #000;
          padding: 20px;
          letter-spacing: 1.5px;
          transition: all 0.35s linear;
          position: relative;
          width: 100%;
          &:after {
            content: "";
            background-size: contain;
            background-image: url("../img/fleche-scroll-blanc.svg");
            transform: rotate(-90deg);
            width: 18px;
            display: inline-block;
            margin-left: 40px;
          }
          &:hover {
            background-color: #fff;
            transition: all 0.35s linear;
            &:after {
              background-image: url("../img/fleche-scroll-jaune.svg");
            }
          }
        }
      }
    }
  }
}

// Newsletter
#newsletter {
  .container-newsletter {
    padding: 30px 30px 10px;
    background-color: var(--jaunec);
    p {
      color: black;
      font-style: italic;
      font-size: 18px;
      font-family: $fbp;
      margin-bottom: 0;
    }
    h4 {
      color: black;
      font-family: $gbo;
      font-size: 22px;
    }
    .frm_style_style-formidable {
      margin-top: 15px;
      .frm_primary_label {
        display: none;
      }
      input[type="email"] {
        background-color: #fff;
        height: 61px;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        font-size: 15px;
        font-family: $gl;
      }
      .frm_submit {
        text-align: right;
        button {
          border: 2px solid #fff;
          background-color: #fff;
          border-radius: 0;
          font-family: $gbo;
          font-size: 15px;
          text-transform: uppercase;
          color: #000;
          padding: 20px;
          letter-spacing: 1.5px;
          transition: all 0.35s linear;
          position: relative;
          width: auto;
          &:after {
            content: "";
            background-size: contain;
            background-image: url("../img/fleche-scroll-jaune.svg");
            transform: rotate(-90deg);
            width: 18px;
            display: inline-block;
            margin-left: 20px;
          }
          &:hover {
            border: 2px solid black;
            background-color: black;
            color: #fff;
            transition: all 0.35s linear;
            &:after {
              background-image: url("../img/fleche-scroll-jaune.svg");
            }
          }
        }
      }
      .frm_form_field {
        width: 80%;
        margin-bottom: 0;
      }
      .frm_fields_container {
        align-items: center;
        display: inline-flex;
        width: 100%;
      }
    }
  }
}

// RESPONSIVE
@media only screen and (max-width: 1920px) {
  #nav-main {
    #menu-menu-principal {
      li {
        a {
          font-size: 40px;
          line-height: 42px;
          .main-nav-description {
            font-size: 26px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1441px) {
  // Equipe
  .page-template-template-equipe {
    #page-intro {
      #page-intro-titre {
        top: 7%;
        right: 25%;
      }
    }
  }
  // Projets
  .projet {
    .projet-infos {
      padding: 110px 80px 110px;
      .projet-date {
        font-size: 28px;
      }
      .projet-titre {
        font-size: 34px;
        line-height: 34px;
      }
      .projet-competences {
        li {
          font-size: 14px;
        }
      }
      .projet-desc {
        margin-top: 50px;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  // Page
  #page-intro {
    width: 100%;
    #page-intro-sous-titre {
      font-size: 45px;
      line-height: 38px;
    }
  }
  // Adn
  .page-template-template-adn {
    #page-intro {
      #page-intro-sous-titre {
        left: 0;
      }
    }
  }
  // Projets
  .projet {
    .projet-infos {
      padding: 100px 60px 100px;
      .projet-date {
        font-size: 26px;
      }
      .projet-titre {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  // Accueil
  #nav-main {
    width: 90%;
    padding: 90px 70px;
    #nav-second {
      position: relative;
      bottom: 0;
      padding-top: 30px;
      padding-bottom: 60px;
    }
  }
  #home-caption {
    p {
      font-size: 60px;
      line-height: 62px;
    }
    h1 {
      font-size: 35px;
    }
  }
  // Page
  #page-intro {
    #page-intro-sous-titre {
      left: -5%;
    }
  }
  .page-template-template-candidature {
    #page-intro {
      #page-intro-sous-titre {
        left: -5%;
      }
    }
  }
  // Coeur de métier
  .page-template-template-metiers {
    #page-intro {
      #page-intro-titre {
        left: 4%;
      }
    }
  }
  // Contact
  .page-template-template-contact
    #form_formulairedecontact
    .frm_checkbox
    label {
    padding: 10px 0;
    font-size: 12px;
  }

  //Equipe
  .page-template-template-equipe {
    #page-intro {
      #page-intro-titre {
        font-size: 80px;
        right: 22%;
      }
      #page-intro-sous-titre {
        font-size: 36px;
      }
    }
  }
  .single #single-intro {
    height: 450px;
  }

  #newsletter {
    .container-newsletter {
      .frm_style_style-formidable {
        .frm_form_field {
          width: 75%;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  // Navigation
  #nav-main {
    width: 100%;
  }
  // Page
  #page-intro-titre {
    font-size: 90px;
  }
  #page-intro #page-intro-sous-titre {
    font-size: 44px;
  }
  // Coeur de métier
  .page-template-template-metiers {
    #page-intro {
    }
  }
  //Equipe
  .page-template-template-equipe {
    #page-intro {
      #page-intro-titre {
        font-size: 70px;
        right: 20%;
      }
      #page-intro-sous-titre {
        font-size: 32px;
      }
    }
  }
  // Services
  .page-template-template-services {
    a:hover {
      display: flex;
      .hover-thumbnail {
        display: none;
      }
    }
  }
  #newsletter {
    .container-newsletter {
      .frm_style_style-formidable {
        .frm_form_field {
          width: 74%;
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  // Navigation
  #nav-main {
    width: 100%;
  }
  // Typographie
  h1 {
    font-size: 90px;
    line-height: 82px;
  }
  h2 {
    font-size: 60px;
    line-height: 52px;
  }
  h3 {
    font-size: 38px;
  }
  .section-row h3 {
    margin-bottom: 0;
  }
  // Accueil
  .projet-colore {
    margin-bottom: 15px;
    a {
      padding: 30px;
    }
  }
  .home {
    .actu {
      .wp-post-image {
        width: 100%;
      }
    }
  }
  #home-actualites {
    .actu {
      img {
        width: 100%;
        object-fit: cover;
        height: 320px;
      }
    }
  }
  // Page
  #page-intro {
    height: auto;
    padding: 40% 0;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    #page-intro-titre {
      left: 0 !important;
      right: 0 !important;
    }
    #page-intro-sous-titre {
      font-size: 40px;
      line-height: 38px;
      left: 0 !important;
      right: 0 !important;
    }
  }
  #scroll-icon {
    bottom: 30px;
    display: none;
  }
  .vagues-4-jaune {
    margin: 40px auto 30px;
  }
  .section-row {
    h2 {
      &:after {
        content: none;
      }
    }
    h3 {
      right: 0;
    }
  }
  // Coeur de métier
  .page-template-template-metiers {
    #page-intro {
      #c-blanc {
        top: -17%;
        left: -2%;
        width: 80%;
      }
      #c-noir-small {
        top: 45px;
        right: 30px;
        width: 30px;
      }
      #vagues-jaune {
        top: 75%;
        left: -5%;
        width: 55%;
        height: 150px;
      }
      #vagues-noir {
        right: 60px;
        bottom: 60px;
        width: 20%;
      }
    }
  }
  // Adn
  .page-template-template-adn {
    #page-intro {
      #c-blanc {
        width: 80%;
        left: 40%;
        top: 24%;
      }
      #c-jaune-small {
        top: 45px;
        right: 30px;
        width: 30px;
      }
      #vagues-blanc {
        top: 20%;
        left: -15%;
        width: 60%;
        opacity: 0.07;
      }
      #vagues-jaune {
        left: 20%;
      }
    }
  }
  // A l'affiche
  .page-template-template-projets,
  .post-type-archive-projets {
    #page-intro {
      #c-blanc {
        width: 70%;
        top: 6%;
      }
      #vagues-jaune {
        z-index: 6;
        left: 5%;
        width: 90%;
        height: 150px;
      }
      #vagues-noir {
        width: 20%;
      }
      #c-noir-small {
        width: 40px;
        top: 8%;
      }
      #virgule-gris {
        top: 6%;
        right: 6%;
        width: 70px;
      }
    }
  }
  .projet {
    .projet-infos {
      // padding: 100px 60px 100px;
      .projet-desc {
        margin-top: 40px;
      }
    }
    .projet-image {
      height: 600px;
    }
  }
  .texte_seul {
    width: 60%;
  }
  // Equipe
  .page-template-template-equipe {
    #photo-equipe,
    .pin {
      display: none;
    }
    #page-intro {
      height: 100%;
      padding: 120px 15px;
      #page-intro-titre {
        position: relative;
        top: 0;
      }
      #page-intro-sous-titre {
        text-align: center;
        width: auto;
        position: relative;
        top: 0;
      }
      #c-blanc {
        width: 80%;
        top: 10%;
        left: 10%;
      }
      #vagues-jaune {
        top: 20%;
        left: -5%;
        width: 60%;
        z-index: 2;
        height: 150px;
      }
      #vagues-noir {
        width: 20%;
        left: 0;
      }
      #c-noir-small {
        width: 40px;
        right: 30px;
        bottom: 30px;
      }
      #virgule-gris {
        top: 6%;
        right: 6%;
        width: 70px;
      }
    }
    #presentation-equipe {
      .glide__slide {
        display: flex;
        flex-direction: column-reverse;
      }
      .infos-salarie {
        width: 100%;
        height: auto;
        float: right;
      }
      .photo-salarie {
        width: 100%;
        float: right;
      }
    }
  }
  #construction {
    display: none;
  }
  .membre-metiers {
    font-size: 34px;
  }
  .membre-texte {
    img,
    picture {
      width: 150px;
      margin: 0;
    }
  }
  .membre-prenom {
    margin-bottom: 30px;
  }
  .membre-photo {
    margin-bottom: 30px;
    img,
    picture {
      width: 100%;
    }
  }
  // Actualites
  .page-template-template-actualites {
    #page-intro {
      #c-blanc {
        width: 70%;
        top: 0;
      }
    }
  }
  #actu-principale {
    img,
    picture {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  .single #single-intro {
    height: 330px;
  }
  #nav-fin-projets {
    .nav-fin-projet {
      margin-bottom: 30px;
      img {
        height: 320px;
        width: 100%;
        object-fit: cover;
      }
      .nav-fin-projet-titre {
        margin-top: 20px;
      }
    }
  }

  // Mentions légales
  .page-id-138 {
    #page-intro {
      background-color: var(--jaunec);
      #c-blanc {
        width: 80%;
        top: 10%;
        left: 10%;
      }
      #vagues-jaune {
        top: 80%;
        left: -5%;
        width: 60%;
        z-index: 2;
        height: 150px;
      }
      #vagues-noir {
        width: 20%;
        left: 0;
      }
      #c-noir-small {
        width: 40px;
        right: 30px;
        bottom: 30px;
      }
    }
    .page-content {
      padding-top: 120px;
      padding-bottom: 90px;
      h2 {
        font-size: 70px;
        margin-top: 40px;
        margin-bottom: 30px;
      }
      h3 {
        font-size: 38px;
      }
      ul {
        li {
          font-family: $gl;
        }
      }
    }
  }
  // COntact
  #contact-questions h2,
  #contact-candidature h2 {
    right: auto;
  }
  .section-row:nth-child(odd) h2,
  .section-row:nth-child(odd) h3 {
    text-align: left;
  }
  .section-row {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .page-template-template-contact #form_formulairedecontact .frm_checkbox {
    width: 100%;
  }
  .page-template-template-contact
    #form_formulairedecontact
    .frm_checkbox
    label {
    padding: 15px 10px;
  }
  .page-template-template-contact
    #form_formulairedecontact
    .frm_submit
    button:after {
    content: none;
  }
  #footer-cta {
    display: none;
  }
  #newsletter {
    .container-newsletter {
      .frm_style_style-formidable {
        .frm_fields_container {
          display: block;
        }
        .frm_form_field {
          width: 100%;
        }
        .frm_submit {
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
@media only screen and (max-height: 870px) {
  #nav-main {
    padding: 15px 100px;
    min-height: calc(100% - 60px);
    height: calc(100vh - 60px);
    #menu-menu-principal {
      li {
        padding: 20px;
        a {
          font-size: 40px;
          .main-nav-description {
            display: none;
          }
        }
      }
    }
    #nav-second {
      padding-left: 20px;
    }
  }
}
@media only screen and (max-width: 768px) {
  // Header
  #nav-main {
    padding: 30px 30px;
    min-height: calc(100% - 60px);
    #menu-menu-principal {
      li {
        padding: 20px;
        a {
          font-size: 40px;
          .main-nav-description {
            display: none;
          }
        }
      }
    }
    #nav-second {
      padding-left: 20px;
    }
    .container-equipe {
      .content-image-equipe {
        display: none;
      }
    }
  }
  #header-logo {
    margin-left: 30px;
  }
  #header-hamburger {
    padding-right: 30px;
  }
  body {
    border-right: 30px solid var(--blanc);
    border-left: 30px solid var(--blanc);
  }

  #progress {
    margin-left: 30px;
    max-width: calc(100% - 60px);
  }

  // Typographie
  h1,
  #page-intro-titre {
    font-size: 70px;
    line-height: 62px;
  }
  h2 {
    font-size: 50px;
    line-height: 42px;
  }
  h3 {
    font-size: 38px;
  }
  .btn-jaune {
    // padding: 10px 20px;
  }
  .home #virgule {
    height: auto;
  }
  .single-post blockquote,
  .single-post h3,
  .single-post iframe,
  .single-post ol,
  .single-post p,
  .single-post ul {
    margin-left: 0;
    margin-right: 0;
  }
  // Equipe
  .page-template-template-equipe {
    #page-intro {
      width: calc(100vw - 60px);
    }
    #presentation-equipe {
      width: calc(100vw - 60px);
    }
  }
  // Projet
  .single #single-projets-titre {
    height: auto;
    h1 {
      margin-top: 45px;
      font-size: 36px;
      line-height: 38px;
    }
    #scroll-icon {
      margin-bottom: 45px;
    }
  }
  .single .single-container h1 {
    font-size: 35px;
    line-height: 37px;
    margin-left: 0;
  }
  .single-post h2 {
    margin-left: 0;
  }
  .single-post ul.post-categories {
    padding: 0;
    display: block;
    margin: 20px 0 0;
  }
  .single-post ul.post-categories li {
    display: block;
  }
  .single-post .btn-jaune:after {
    display: none;
  }
  .prestation-item {
    padding-left: 15px;
  }
  #home-clients ul li {
    width: 50%;
    &:nth-child(9) {
      display: none;
    }
  }
  .projets-colores #projets-deco-2 {
    left: 10%;
  }
  .home .overlay {
    width: calc(100vw - 30px);
  }
  #home-hero-video {
    width: calc(100vw - 30px);
    height: calc(100vh - 30px);
  }
  // Page 404
  .error404 {
    #section404 {
      width: 100%;
    }
  }
  // Page article
  #actualites {
    .actu-multiple {
      .actu-image {
        img {
          height: 320px;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .main-footer-expand {
    padding-bottom: 160px;
  }
  .single {
    #single-nav {
      ul {
        li {
          a {
            padding: 25px;
          }
          #single-nav-prev,
          #single-nav-next {
            svg {
              display: none;
            }
          }
        }
      }
    }
  }
  // page projet
  .projet {
    .projet-image {
      height: 400px;
    }
  }
}
@media only screen and (max-width: 640px) {
  #home-caption {
    padding: 0 10%;
    p {
      font-size: 60px;
      line-height: 62px;
    }
    h1  {
      font-size: 34px;
      line-height: 28px;
    }
  }
  .page-content {
    margin-bottom: 240px;
  }
  // page projet
  .projet {
    .projet-image {
      height: 350px;
    }
  }
  footer {
    &.footer-expand {
      padding-bottom: 60px;
      #footer-cta {
        text-align: center;
        padding-left: 0;
        display: block;
      }
    }
    #footer-social {
      position: relative;
      text-align: center;
      right: auto;
      bottom: auto;
    }
  }
  // Equipe
  .page-template-template-equipe {
    #presentation-equipe {
      margin-bottom: 60px;
      h3 {
        font-size: 48px;
      }
      .glide__arrows {
        display: none;
      }
      .vagues-4-blanc {
        display: none;
      }
      .glide__slide {
        display: flex;
        flex-direction: column-reverse;
      }
      .infos-salarie {
        padding: 90px 30px;
      }
      .photo-salarie {
        width: 100%;
        height: 400px;
      }
    }
  }

  .page-template-template-candidature {
    #page-intro-titre,
    h1 {
      font-size: 60px;
      word-break: break-all;
    }
    #page-intro {
      #page-intro-titre:after {
        display: none;
      }
    }
  }

  footer {
    #theme_switch {
      padding-left: 40px;
    }
    label {
      width: 57px;
      height: 34px;
    }
    .fa-moon,
    .fa-sun {
      width: 20px;
      height: 20px;
    }
    .ball {
      width: 25px;
      height: 25px;
      top: 4px;
      left: 4px;
    }
  }
}
@media only screen and (max-width: 541px) {
  // Page Single Services
  .services-template-default.single-services {
    #page-intro-titre {
      font-size: 55px;
    }
  }
  #nav-main {
    .container-equipe {
      .content-nav {
        padding: 30px 15px;
      }
    }
    .container-contact,
    .container-recrutement {
      padding: 30px 15px;
    }
  }
  .single #single-intro {
    height: 250px;
  }
  .main-telephone {
    display: none;
  }
  .hamburger {
    display: block;
    margin-top: -6px;
  }
}
@media only screen and (max-width: 425px) {
  #header-logo {
    margin-left: 15px;
    img,
    picture {
      max-width: 125px;
    }
  }
  #header-hamburger {
    padding-right: 15px;
  }
  #nav-main {
    padding: 15px;
    #menu-menu-principal {
      li {
        padding: 20px;
        a {
          font-size: 34px;
        }
      }
    }
  }
  .home main {
    padding-bottom: 220px;
  }
  .home #virgule {
    width: 100%;
    height: auto;
    right: 0;
    top: 15%;
  }
  #actu-principale {
    .actu-titre {
      font-size: 44px;
      line-height: 46px;
    }
  }
  .actu {
    .actu-titre {
      font-size: 32px;
      line-height: 36px;
    }
  }
  #actualites-filtres {
    ul {
      li {
        display: block;
        text-align: left;
        a {
        }
      }
    }
  }
  .projet {
    .projet-infos {
      // padding: 90px 15px 45px 15px;
      .projet-date {
        font-size: 26px;
      }
      .projet-titre {
        font-size: 26px;
        line-height: 28px;
        margin-bottom: 15px;
      }
      .projet-desc {
        margin-top: 40px;
        p {
          font-size: 16px;
          line-height: 22px;
        }
      }
      .projet-competences {
        li {
          font-size: 15px;
        }
      }
    }
  }
  #page-intro-titre {
    font-size: 60px;
    line-height: 52px;
  }
  #page-intro #page-intro-sous-titre,
  h3 {
    font-size: 30px;
    line-height: 34px;
  }
  .page-cta {
    font-size: 30px;
  }
  .single .single-container h1 {
    font-size: 28px;
    line-height: 30px;
  }
  #home-clients ul li {
    width: 100%;
    &:nth-child(9) {
      display: flex;
    }
  }
  // Page Single Services
  .services-template-default.single-services {
    #page-intro-titre {
      font-size: 45px;
      line-height: 35px;
    }
    &.postid-919,
    &.postid-893,
    &.postid-920,
    &.postid-900 {
      #page-intro {
        #page-intro-sous-titre {
          font-size: 25px;
          text-align: left;
        }
      }
    }
  }
  .single {
    #single-nav {
      ul {
        display: block;
        li {
          display: block;
        }
      }
    }
  }
}
@media only screen and (max-width: 641px) {
  .home main {
    padding-bottom: 220px;
  }
  .home {
    section {
      // padding-left: 15px;
      // padding-right: 15px;
    }
  }
  #home-caption p:after {
    display: none;
  }
}
@media only screen and (max-width: 541px) {
  .btn-jaune {
    a {
      display: block;
    }
  }
}
@media only screen and (max-width: 481px) {
  #progress {
    margin-left: 0px;
    max-width: calc(100% - 0px);
  }
  body {
    border-left: 0;
    border-right: 0;
  }
  section {
    padding-left: 0;
    padding-right: 0;
  }
  #home-hero-video,
  .home .overlay {
    width: 100%;
  }
  .home main {
    padding-bottom: 220px;
  }
}
@media only screen and (max-width: 421px) {
  footer {
    #theme_switch {
      padding-left: 10px;
    }
  }
}
@media only screen and (max-width: 375px) {
  body {
    border-left: 0;
    border-right: 0;
  }
  section {
    padding-left: 0;
    padding-right: 0;
  }
  #home-hero-video {
    width: 100%;
    height: 100%;
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .home .overlay {
    width: 100%;
  }
  h2 {
    font-size: 40px;
    line-height: 32px;
  }
  #page-intro #page-intro-sous-titre,
  h3 {
    font-size: 22px;
    line-height: 24px;
  }
  #currentTime {
    margin-bottom: 10px;
  }
  #home-caption h1 {
    font-size: 28px;
  }
  #home-caption p {
    font-size: 46px;
    line-height: 46px;
  }
  // Equipe
  .page-template-template-equipe {
    #page-intro,
    #presentation-equipe {
      width: 100vw;
    }
  }
}

// ARTICLE CALENDRIER MARKETING
#form_calendriermarketing {
  margin-left: 12%;
  margin-right: 12%;
}
#form_calendriermarketing,
#form_candidaturespontane,
#form_candidatureweb {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="url"] {
    background-color: var(--grisf);
    height: 55px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    font-size: 15px;
    font-family: $gl;
  }
  textarea {
    background-color: var(--grisf);
    min-height: 295px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
  #frm_field_12_container {
    padding-top: 15px;
  }
  .frm_primary_label {
    font-family: $gbo;
    font-size: 16px;
    color: var(--noir);
  }
  .frm_checkbox {
    float: left;
    width: 20%;
    text-align: center;
    border: 1px solid var(--grisc);
    label {
      padding: 20px 10px;
      text-transform: uppercase;
      font-family: $gl;
      font-size: 14px;
      background-color: var(--grisf);
      margin-bottom: 0;
      cursor: pointer;
      transition: all 0.35s linear;
    }
    input {
      opacity: 0;
    }
  }
  .checked {
    background-color: var(--jaunec) !important;
    transition: all 0.35s linear;
  }
  .frm_dropzone .dz-message {
    background-color: var(--grisf);
    margin-bottom: 0;
  }
  .frm_submit {
    text-align: right;
    button {
      border: 2px solid var(--jaunec);
      background-color: var(--jaunec);
      border-radius: 0;
      font-family: $gbo;
      font-size: 15px;
      text-transform: uppercase;
      color: #000;
      padding: 20px;
      letter-spacing: 1.5px;
      transition: all 0.35s linear;
      position: relative;
      &:after {
        content: "";
        background-size: contain;
        background-image: url("../img/fleche-scroll-blanc.svg");
        transform: rotate(-90deg);
        width: 18px;
        display: inline-block;
        margin-left: 40px;
      }
      &:hover {
        background-color: #fff;
        transition: all 0.35s linear;
        &:after {
          background-image: url("../img/fleche-scroll-jaune.svg");
        }
      }
    }
  }
}

#form_candidaturegraphiste {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="url"] {
    background-color: var(--grisf);
    height: 55px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    font-size: 15px;
    font-family: $gl;
  }
  textarea {
    background-color: var(--grisf);
    min-height: 295px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
  #frm_field_12_container {
    padding-top: 15px;
  }
  .frm_primary_label {
    font-family: $gbo;
    font-size: 16px;
    color: var(--noir);
  }
  // .frm_checkbox {
  // 	float: left;
  // 	width: 20%;
  // 	text-align: center;
  // 	border: 1px solid var(--grisc);
  // 	label {
  // 		padding: 20px 10px;
  // 		text-transform: uppercase;
  // 		font-family: $gl;
  // 		font-size: 14px;
  // 		background-color: var(--grisf);
  // 		margin-bottom: 0;
  // 		cursor: pointer;
  // 		transition: all .35s linear;
  // 	}
  // }
  .checked {
    background-color: var(--jaunec) !important;
    transition: all 0.35s linear;
  }
  .frm_submit {
    text-align: right;
    button {
      border: 2px solid var(--jaunec);
      background-color: var(--jaunec);
      border-radius: 0;
      font-family: $gbo;
      font-size: 15px;
      text-transform: uppercase;
      color: #000;
      padding: 20px;
      letter-spacing: 1.5px;
      transition: all 0.35s linear;
      position: relative;
      &:after {
        content: "";
        background-size: contain;
        background-image: url("../img/fleche-scroll-blanc.svg");
        transform: rotate(-90deg);
        width: 18px;
        display: inline-block;
        margin-left: 40px;
      }
      &:hover {
        background-color: #fff;
        transition: all 0.35s linear;
        &:after {
          background-image: url("../img/fleche-scroll-jaune.svg");
        }
      }
    }
  }
}

#myModal {
  z-index: 9999;
  .modal-dialog {
    max-width: 720px;
    // max-height: 90vh;
    // overflow: scroll;
  }
  .modal-content {
    border: 6px solid var(--jaunec);
    background-color: var(--blanc);

    h2 {
      font-size: 40px;
      font-family: $gbl;
      color: var(--noir);
    }
    svg {
      position: absolute;
      top: -10px;
      right: -10px;
      margin: 0;
      height: 30px;
      width: 30px;
      path,
      circle {
        fill: var(--noir);
      }
      path,
      circle {
        stroke: var(--noir);
      }
    }

    #close {
      cursor: pointer;
    }
    .close {
      background-color: transparent;
      border: none;
      position: relative;
      span {
        display: block;
        position: relative;
        font-size: 16px;
      }
    }
  }
  .modal-body {
  }
}
